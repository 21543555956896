import React from "react";
import {
  Content,
  MainWrapper,
  Header,
  Menu,
  Column,
  Metadata,
} from "../../components";
import { Watchlist } from "./WatchList";

export const view = ({ contentRef, user }) => {
  return (
    <MainWrapper drawer className="dashboard">
      <Metadata />
      <Header drawer login user />
      <Menu />

      <Content ref={contentRef}>
        <Column>
          <Watchlist {...{ contentRef }} />
        </Column>
      </Content>
    </MainWrapper>
  );
};
