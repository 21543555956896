import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  productActions,
  diseaseActions,
  conferenceActions,
  userActions,
  planActions,
  tagActions,
} from "../../store/actions";
import {
  cancelProductRequests,
  cancelDiseaseRequests,
  cancelConferenceRequests,
  cancelPlanRequests,
} from "../../store/sagas";
import { useGetRegions } from "../../store/queries/regions";
import {
  // getMonitors,
  createMonitor,
  updateMonitor,
} from "../../store/sagas/monitorSagas";
import { useGetMonitors } from "../../store/queries/monitors";
import useDebounce from "../../utils/useDebounce";
import { Color, MAX_RESULTS } from "../../utils/variables";
import {
  isReady,
  logAnalyticEvent,
  getUrlParams,
  formatShortDates,
} from "../../utils";
import { getApiPath } from "../../utils/variables";

import {
  Row,
  Column,
  H3,
  H4,
  Label,
  Grid,
  Button,
  HR,
  Anchor,
  Modal,
  SelectList,
  Pill,
  Dropdown,
  Toast,
  Tabs,
  Icon,
  IconButton,
  Radios,
  AutoCompleteWithResponses,
  Dictionary,
  Group,
  Input,
} from "../../components";

const initialNewMonitor = {
  monitorComponent: {
    object1Type: undefined,
    object1Id: undefined,
  },
  notificationType: "EMAIL",
  notificationIntervalId: undefined,
  notificationInterval: undefined,
  visible: true,
};

const intervals = [
  /*for diseases*/
  {
    id: "1",
    value: "168",
    label: "Weekly",
    visible: true,
  },
  /*for all*/
  {
    id: "2",
    value: "24",
    label: "Daily",
    visible: true,
  },
  /*for products*/
  {
    id: "3",
    value: "0",
    label: "Instant",
    visible: true,
  },
  /*for all*/
  {
    id: "4",
    value: "-1",
    label: "Off",
    visible: true,
  },
];

const LeftBorder = styled.div`
  width: 20px;
  height: 100%;
  background: ${Color("green", "5")};
`;

const Banner = ({ title, description, setBannerData }) => (
  <Row marginBottom>
    <Column border="green" radius="10">
      <Row>
        <LeftBorder />
        <Column paddingAll="10" gap="5">
          <Label bold>{title}</Label>
          <Label>{description}</Label>
        </Column>
        <Row paddingAll="3">
          <IconButton
            small
            icon="closeLarge"
            onClick={() => setBannerData({ name: "", email: "", type: "" })}
          />
        </Row>
      </Row>
    </Column>
  </Row>
);

const Monitor = ({
  type,
  monitors,
  intervals,
  regions,
  toggleMonitorModal,
  toggleDeleteModal,
  setSelectedMonitor,
  handleMonitorType,
  selectedPlan,
  conferences,
  userPlan,
  toggleFiltersModal,
  setSelectedRegions,
  setSelectedDiseases,
  adminPage,
  isHcp,
}) => {
  const visibleMonitors = monitors?.filter((monitor) => monitor.visible) || [];

  let limit = true;
  switch (type) {
    case "DISEASE":
      const diseasesValue =
        selectedPlan?.diseases && parseInt(selectedPlan.diseases);
      const diseasesLength = visibleMonitors.filter(
        (m) => m.type === "DISEASE"
      ).length;
      limit = diseasesLength < diseasesValue || diseasesValue === -1;
      break;

    case "PRODUCT":
      const productsValue =
        selectedPlan?.products && parseInt(selectedPlan.products);
      const productsLength = visibleMonitors.filter(
        (m) => m.type === "PRODUCT"
      ).length;
      limit = productsLength < productsValue || productsValue === -1;
      break;
  }

  return (
    <Grid columns={3} gap="20" jump3="laptopL">
      {visibleMonitors.map((monitor) => {
        const interval = intervals.find(
          (a) => a.value === monitor.notificationInterval.toString()
        )?.label;
        let dates = null;
        if (conferences) {
          const conf = conferences.find((c) => c.id === monitor.object1Id);
          if (conf) {
            dates = formatShortDates(conf.startDate, conf.endDate);
          }
        }
        return (
          <Column
            bkg="gray"
            fade="1"
            radius="15"
            key={monitor.id}
            paddingAll
            gap="10"
            minHeight="138"
          >
            <H4 bold>{monitor.label}</H4>
            {type === "CONFERENCE" ? (
              <Column fit bottom gap="5">
                <Row gap="5" middle>
                  <Icon color="gray" fade="7" name="email" />
                  <Label color="gray" fade="7">
                    {interval === "Off" ? "Off" : `${interval} email`}
                  </Label>
                </Row>
                {dates && !adminPage && (
                  <Row gap="5" middle>
                    <Icon color="gray" fade="7" name="calendar" />
                    <Label color="gray" fade="7">
                      {dates}
                    </Label>
                  </Row>
                )}
              </Column>
            ) : (
              <Row gap="5" middle>
                <Icon color="gray" fade="7" name="email" />
                <Label color="gray" fade="7">
                  {interval === "Off" ? "Off" : `${interval} email`}
                </Label>
              </Row>
            )}
            {/* {monitor.monitorRules?.length > 0 && (
              <Row flexWrap>
                {monitor.monitorRules.map((rule) => {
                  let text = undefined;
                  if (rule.region) {
                    text = regions.find((r) => r.id === rule.region)?.name;
                  } else {
                    text = rule.secondTagName;
                  }

                  if (!text) return null;
                  return (
                    <Pill
                      key={rule.secondTagId || rule.region}
                      small
                      label={text}
                      marginRight="5"
                    />
                  );
                })}
              </Row>
            )} */}
            {(type === "PRODUCT" ||
              type === "DISEASE" ||
              (type === "CONFERENCE" && adminPage)) && (
              <Column fit bottom>
                <Row marginTop="10" gap="30">
                  <Anchor
                    bold
                    color="red"
                    label="Unfollow"
                    onClick={() => {
                      setSelectedMonitor(monitor);
                      toggleDeleteModal(true);
                    }}
                  />

                  {userPlan?.plan?.name &&
                    !userPlan.plan.name.includes("Free") &&
                    !userPlan.plan.name.includes("Basic") && (
                      <Anchor
                        bold
                        label={`Filters${
                          monitor.monitorRules?.length > 0
                            ? ` (${monitor.monitorRules?.length})`
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedMonitor(monitor);
                          const tempRegions = monitor.monitorRules
                            .filter((rule) => rule.region)
                            .map((rule) => {
                              return {
                                id: rule.region,
                                label: regions.find((r) => r.id === rule.region)
                                  ?.label,
                              };
                            });
                          setSelectedRegions(tempRegions);

                          if (monitor?.type === "PRODUCT") {
                            const tempTags = monitor.monitorRules
                              .filter((rule) => rule.secondTagName)
                              .map((rule) => {
                                return {
                                  id: rule.secondTagId,
                                  label: rule.secondTagName,
                                };
                              });
                            setSelectedDiseases(tempTags);
                          }
                          toggleFiltersModal(true);
                        }}
                      />
                    )}
                </Row>
              </Column>
            )}
          </Column>
        );
      })}
      {toggleMonitorModal && (
        <>
          {/* {adminPage || limit ? ( */}
          {limit ? (
            <div
              className="dots pointer"
              onClick={() => {
                handleMonitorType(type);
                toggleMonitorModal(true);
              }}
            >
              <Icon color="green" fade="6" name="add" />
              <Label color="green" fade="6" bold>
                Follow
              </Label>
            </div>
          ) : (
            <>
              {isHcp ? (
                <Column
                  gap="20"
                  center
                  middle
                  border="green"
                  radius="15"
                  paddingAll
                >
                  <Label center>Want to follow therapies?</Label>
                  <Anchor
                    bold
                    label="Contact us"
                    blank
                    to="mailto:hello@medical.watch"
                  />
                </Column>
              ) : (
                <Column
                  gap="20"
                  center
                  middle
                  border="green"
                  radius="15"
                  paddingAll
                >
                  <Label center>
                    Youve reached the limit. Upgrade your plan to follow more{" "}
                    {type.toLowerCase()}s.
                  </Label>
                  <Anchor bold label="View plans" to="/pricing" />
                </Column>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const MonitorList = ({ type, monitors, intervals, handleUpdateMonitor }) => {
  const visibleMonitors = monitors?.filter((monitor) => monitor.visible) || [];

  return (
    <Column marginTop="10" gap="20">
      {visibleMonitors.map((monitor, index) => {
        const intervalIndex = intervals.findIndex(
          (a) => a.value === monitor.notificationInterval.toString()
        );
        let tempIntervals = [];
        intervals.forEach((interval, index) => {
          tempIntervals.push({ ...interval, checked: index === intervalIndex });
        });
        let initialIntervals =
          type === "DISEASE"
            ? [tempIntervals[3], tempIntervals[0]]
            : type === "CONFERENCE"
            ? [tempIntervals[3], tempIntervals[1]]
            : [
                tempIntervals[3],
                tempIntervals[2],
                tempIntervals[1],
                tempIntervals[0],
              ];
        const [items, setItems] = useState(initialIntervals);

        const handleChange = (e) => {
          setItems(e);
          const value = e.find((a) => a.checked)?.value;
          handleUpdateMonitor({
            id: monitor.id,
            visible: monitor.visible,
            notificationInterval: value,
          });
        };

        return (
          <Column key={monitor.id} gap="10" marginBottom="-5">
            {index === 0 && <HR />}
            <Grid gap="10" columns={2}>
              <Row marginTop="15">
                <Label bold>{monitor.label}</Label>
              </Row>
              <Row gap="5">
                <Radios items={items} onChange={handleChange} />
              </Row>
            </Grid>
            <HR />
          </Column>
        );
      })}
    </Column>
  );
};

const AdditionalContent = ({ preferences, updateAdditionalContent }) => {
  let topInfluencersItems = [
    {
      id: 1,
      value: "0",
      label: "Off",
    },
    {
      id: 2,
      value: "1",
      label: "Monthly",
      visible: true,
    },
  ];
  topInfluencersItems[
    preferences?.find(
      (p) => p.preferenceName === "TopInfluencersEmail"
    )?.preferenceIntValue
  ].checked = true;

  let topBransdItems = [
    {
      id: 1,
      value: "0",
      label: "Off",
    },
    {
      id: 2,
      value: "1",
      label: "Monthly",
      visible: true,
    },
  ];
  topBransdItems[
    preferences?.find(
      (p) => p.preferenceName === "TopBrandMentionsEmail"
    )?.preferenceIntValue
  ].checked = true;

  let topArticlesdItems = [
    {
      id: 1,
      value: "0",
      label: "Off",
    },
    {
      id: 2,
      value: "1",
      label: "Monthly",
      visible: true,
    },
  ];
  topArticlesdItems[
    preferences?.find(
      (p) => p.preferenceName === "TopArticlesEmail"
    )?.preferenceIntValue
  ].checked = true;

  const [topInfluencers, setTopInfluencers] = useState(topInfluencersItems);
  const [topBrands, setTopBrands] = useState(topBransdItems);
  const [topArticles, setTopArticles] = useState(topArticlesdItems);

  const handleChange = (field, e) => {
    field === "TopInfluencersEmail" && setTopInfluencers(e);
    field === "TopBrandMentionsEmail" && setTopBrands(e);
    field === "TopArticlesEmail" && setTopArticles(e);
    updateAdditionalContent(
      field,
      e.findIndex((a) => a.checked)
    );
  };

  return (
    <Column marginTop="10" gap="20">
      <Column gap="10" marginBottom="-5">
        <HR />
        <Grid gap="10" columns={2}>
          <Row marginTop="15">
            <Label bold>Top influencers</Label>
          </Row>
          <Row gap="5">
            <Radios
              items={topInfluencers}
              onChange={(e) => handleChange("TopInfluencersEmail", e)}
            />
          </Row>
        </Grid>
        <HR />
        <Grid gap="10" columns={2}>
          <Row marginTop="15">
            <Label bold>
              <Dictionary prepend="Top " word="products" />
            </Label>
          </Row>
          <Row gap="5">
            <Radios
              items={topBrands}
              onChange={(e) => handleChange("TopBrandMentionsEmail", e)}
            />
          </Row>
        </Grid>
        <HR />
        <Grid gap="10" columns={2}>
          <Row marginTop="15">
            <Label bold>Top articles</Label>
          </Row>
          <Row gap="5">
            <Radios
              items={topArticles}
              onChange={(e) => handleChange("TopArticlesEmail", e)}
            />
          </Row>
        </Grid>
        <HR />
      </Column>
    </Column>
  );
};

export const Watchlist = ({ user, adminPage, contentRef }) => {
  const dispatch = useDispatch();
  const { data: initialRegions } = useGetRegions();

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  const viewedUser = useSelector((state) => state.user.one);
  const plans = useSelector((state) => state.plan.all);
  const basePlans = useSelector((state) => state.plan.basePlans);
  const userPlans = useSelector((state) => state.plan.userPlans);
  const initialPreferences = useSelector((state) => state.user.preferences);
  const isAdmin = auth?.mwRole === "ADMIN";
  const indications = useSelector((state) => state.disease.all.data)?.map(
    (item) => ({ ...item, label: item.primaryName })
  );
  // <-- mapStateToProp

  const userId = user?.id || auth.id;
  const { data: initialMonitors, refetch: refetchMonitors } =
    useGetMonitors(userId);

  // --> STATE
  const [monitors, setMonitors] = useState([]);
  const [monitorModal, toggleMonitorModal] = useState(false);
  const [filtersModal, toggleFiltersModal] = useState(false);
  const [newMonitor, setNewMonitor] = useState(initialNewMonitor);
  const [preferences, setPreferences] = useState({});
  const [activeTab, setActiveTab] = useState("diseases");
  const [bannerData, setBannerData] = useState({
    name: "",
    email: "",
    type: "",
  });

  const initialProducts = useSelector((state) => state.product.all);
  const initialDiseases = useSelector((state) => state.disease.all.data);
  const initialConferences = useSelector((state) => state.conference.all.data);
  const [searchProduct, setSearchProduct] = useState("");
  const debounceProduct = useDebounce(searchProduct, 700);
  const [products, setProducts] = useState([]);
  const [pageProducts, setPageProducts] = useState(0);
  const [diseases, setDiseases] = useState([]);
  const [conferences, setConferences] = useState([]);
  const [regions, setRegions] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  const [defaultPlan, setDefaultPlan] = useState(null);
  const [plansList, setPlansList] = useState([]);
  const [canEditPlan, setCanEditPlan] = useState(false);
  const [queryParams, setQueryParams] = useState(getUrlParams(location.search));
  const [showToast, setShowToast] = useState(false);
  const [selectedMonitor, setSelectedMonitor] = useState({});
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [searchDisease, setSearchDisease] = useState("");
  const debounceSearchDisease = useDebounce(searchDisease, 700);
  const [regionTerm, setRegionTerm] = useState("");
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [userData, setUserData] = useState(auth);
  // <-- STATE

  const cookies = useSelector((state) => state.settings.cookies);

  const handleChange = (field, value) => {
    setUserData({ ...userData, [field]: value });
  };

  const handleUpdate = () => {
    console.log("userData", userData);
  };

  const getUserType = () => {
    return (
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL"
    );
  };

  const requestProducts = (pageNum) => {
    dispatch(
      productActions.request({
        ignore: false,
        maxResult: MAX_RESULTS,
        ...(searchProduct !== "" && { term: searchProduct }),
        pageNum,
      })
    );
  };

  // --> EFFECT
  useEffect(() => {
    dispatch(planActions.request());
    dispatch(planActions.basePlansRequest());

    return () => {
      setUserPlan(null); // nullify plan for the viewed user
      dispatch(planActions.clear());

      cancelPlanRequests.abort();
      cancelDiseaseRequests.abort();
      cancelProductRequests.abort();
      cancelConferenceRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (basePlans?.data?.length > 0 && userPlan?.plan) {
      const basePlan = basePlans?.data?.find(
        (item) => item.name === userPlan.plan.name
      );
      if (basePlan) {
        setSelectedPlan({
          diseases: basePlan?.planFeatures?.filter(
            (item) => item.name === "DISEASE"
          )?.[0]?.value,
          products: basePlan?.planFeatures?.filter(
            (item) => item.name === "PRODUCT"
          )?.[0]?.value,
        });
      }
    }
  }, [basePlans, userPlan]);

  useEffect(() => {
    if (queryParams?.newPlan) {
      setShowToast(true);
      setQueryParams({});
      window.history.replaceState(null, "Medical.watch", "/preferences");
    }
  }, [queryParams]);

  useEffect(() => {
    if (auth.active) {
      dispatch(
        userActions.requestPreferences({
          id: userId,
        })
      );
      dispatch(diseaseActions.request({ ignore: false, maxResult: -1 }));
      dispatch(
        conferenceActions.request({
          ignore: false,
          onlyParents: true,
          maxResult: -1,
        })
      );
    }

    // requestDiseases()

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Manage watchlist",
      {}, // event properties
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      getUserType(),
      userPlan?.plan?.name || null
    );
  }, [auth]);

  useEffect(() => {
    if (auth.active && initialRegions?.data?.length > 0) {
      const rgns = [
        {
          id: "ALL",
          name: "All Regions",
          label: "All Regions",
        },
      ];
      initialRegions.data.forEach((row) => {
        rgns.push({
          id: row.id,
          name: row.name,
          label: row.name,
        });
      });
      setRegions(rgns);
    }
  }, [initialRegions?.data]);

  const requestDiseases = () => {
    dispatch(
      diseaseActions.request({
        ignore: false,
        ...(searchDisease !== "" && { term: searchDisease }),
        maxResult: -1,
      })
    );
  };

  useEffect(() => {
    requestDiseases();
  }, [debounceSearchDisease]);

  useEffect(() => {
    if ((isReady(viewedUser) && !_.isEmpty(viewedUser.data)) || !user) {
      const userId =
        user?.id === viewedUser.data?.id
          ? viewedUser.data?.id || auth.id
          : auth.id;
      dispatch(planActions.userPlansRequest({ userId: userId, active: true }));
    }
  }, [viewedUser]);

  useEffect(() => {
    setPageProducts(0);
    requestProducts(0);
  }, [debounceProduct]);

  useEffect(() => {
    const newItems = initialProducts.data.map((item) => ({
      id: item.id,
      label: item.primaryName,
    }));
    setProducts(newItems);
  }, [initialProducts.data]);

  useEffect(() => {
    if (initialDiseases.length > 0) {
      const newItems = initialDiseases.map((item) => ({
        id: item.id,
        label: item.primaryName,
      }));
      setDiseases(newItems);
    }
  }, [initialDiseases]);

  useEffect(() => {
    if (initialConferences.length > 0) {
      const newItems = initialConferences
        .filter((c) => !c.parentId)
        .map((item) => ({
          id: item.id,
          label: item.primaryName,
          startDate: item.startDate,
          endDate: item.endDate,
        }));
      setConferences(newItems);
    }
  }, [initialConferences]);

  useEffect(() => {
    if (initialPreferences.data?.length > 0) {
      let tempPreferences = [];
      const topInfluencersPreference = initialPreferences.data.find(
        (p) => p.preferenceName === "TopInfluencersEmail"
      );
      tempPreferences.push(
        topInfluencersPreference || {
          preferenceName: "TopInfluencersEmail",
          preferenceIntValue: 1,
        }
      );
      const topBrandsPreference = initialPreferences.data.find(
        (p) => p.preferenceName === "TopBrandMentionsEmail"
      );
      tempPreferences.push(
        topBrandsPreference || {
          preferenceName: "TopBrandMentionsEmail",
          preferenceIntValue: 1,
        }
      );
      const topArticlesPreference = initialPreferences.data.find(
        (p) => p.preferenceName === "TopArticlesEmail"
      );
      tempPreferences.push(
        topArticlesPreference || {
          preferenceName: "TopArticlesEmail",
          preferenceIntValue: 1,
        }
      );
      setPreferences(tempPreferences);
    }
  }, [initialPreferences.success]);

  useEffect(() => {
    if (isReady(plans) && plans.data.length > 0) {
      const defaultPlan = plans.data.find((p) => p.default);
      setDefaultPlan(defaultPlan);
      // TODO: we are only changing from free to enterprise by now
      // free: true || default: true
      const templans = plans?.data
        ?.filter((a) => a.free || a.default)
        ?.map((p) => ({
          id: p.id,
          label: p.name,
        }));
      setPlansList(templans);
    }
  }, [plans]);

  useEffect(() => {
    if (userPlan?.plan) {
      // We won't need once we can update all plans
      setCanEditPlan(userPlan.plan.free || userPlan.plan.default);
    } else {
      // We won't need once we can update all plans
      setCanEditPlan(true);
    }
  }, [userPlan]);

  useEffect(() => {
    if (!userPlans?.data?.[0]) {
      return;
    }
    if (!userPlans.fetching) {
      const activePlans = userPlans.data.filter((p) => p.active);
      if (activePlans.length > 0) {
        // check if the user being viewed is the same as active plan's user
        // or if the active plan's user is the same as the logged in user
        if (
          activePlans[0].email === viewedUser.data?.email ||
          (activePlans[0].email === auth.email && userId === auth.id)
        ) {
          setUserPlan(activePlans[0]);
        }
      }
    }
  }, [userPlans]);

  // <-- EFFECT

  const handleMonitorType = (type) => {
    setNewMonitor((oldMonitor) => ({
      ...oldMonitor,
      monitorComponent: {
        ...oldMonitor.monitorComponent,
        object1Type: type,
      },
      notificationIntervalId: type === "DISEASE" ? "1" : "2",
      notificationInterval: type === "DISEASE" ? "168" : "24",
    }));
  };

  const handleResetNewMonitor = () => {
    setNewMonitor(initialNewMonitor);
  };

  const handleSearchProduct = (e) => {
    setSearchProduct(e);
  };

  const handleMoreProducts = () => {
    if (!initialProducts.fetching) {
      const productsNewPage = pageProducts + 1;
      setPageProducts(productsNewPage);
      requestProducts(productsNewPage);
    }
  };

  const productsInfinteScroll = {
    loading: initialProducts.fetching,
    hasNextPage: !initialProducts.isLastPage,
    onLoadMore: handleMoreProducts,
  };

  const handleSaveMonitor = async (e) => {
    const selectedMonitor = {
      ...newMonitor,
      monitorComponent: {
        ...newMonitor.monitorComponent,
        object1Id: e.id,
      },
    };

    toggleMonitorModal(false);
    setNewMonitor(initialNewMonitor);
    let monitorList = [];

    switch (selectedMonitor.monitorComponent.object1Type) {
      case "DISEASE":
        const diseaseExists = monitors.disease?.find(
          (m) => m.object1Id === selectedMonitor.monitorComponent.object1Id
        );
        if (diseaseExists) {
          // update monitor
          await updateMonitor({
            id: diseaseExists.id,
            visible: true,
            notificationInterval: Number(selectedMonitor.notificationInterval),
            userId,
          });
        } else {
          // create monitor
          await createMonitor(selectedMonitor, userId);
          setBannerData({
            name: e.label,
            email: intervals[0].label,
            type: "disease",
          });
          contentRef &&
            contentRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }

        refetchMonitors();
        break;

      case "PRODUCT":
        const productExists = monitors.product?.find(
          (m) => m.object1Id === selectedMonitor.monitorComponent.object1Id
        );
        if (productExists) {
          // update monitor
          await updateMonitor({
            id: productExists.id,
            visible: true,
            notificationInterval: Number(selectedMonitor.notificationInterval),
            userId,
          });
        } else {
          // create monitor
          await createMonitor(selectedMonitor, userId);
          setBannerData({
            name: e.label,
            email: intervals[1].label,
            type: "product",
          });
          contentRef &&
            contentRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }

        refetchMonitors();
        break;

      case "CONFERENCE":
        const conferenceExists = monitors.conference?.find(
          (m) => m.object1Id === selectedMonitor.monitorComponent.object1Id
        );
        if (conferenceExists) {
          // update monitor
          await updateMonitor({
            id: conferenceExists.id,
            visible: true,
            notificationInterval: Number(selectedMonitor.notificationInterval),
            userId,
          });
        } else {
          // create monitor
          await createMonitor(selectedMonitor, userId);
          setBannerData({
            name: e.label,
            email: intervals[1].label,
            type: "conference",
          });
          contentRef &&
            contentRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }

        refetchMonitors();
        break;
    }

    setSearchProduct("");

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Add to watchlist",
      {
        monitorType: newMonitor.monitorComponent.object1Type,
        monitorId: newMonitor.monitorComponent.object1Id,
        notificationInterval: newMonitor.notificationInterval,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      getUserType(),
      userPlan?.plan?.name || null
    );
  };

  const handleUpdatePlan = (e) => {
    if (user?.email) {
      dispatch(planActions.userPlansUpdate({ planId: e.id, userId: userId }));
      setTimeout(() => {
        refetchMonitors();
        dispatch(
          planActions.userPlansRequest({ userId: userId, active: true })
        );
      }, 1000);
    }
  };

  const handleDeleteMonitor = async (monitor) => {
    await updateMonitor({
      id: monitor.id,
      visible: false,
      notificationInterval: monitor.notificationInterval,
      userId,
    });

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Remove from watchlist",
      {
        id: monitor.id,
        monitorType: monitor.monitorComponent.object1Type,
        monitorId: monitor.monitorComponent.object1Id,
        monitorName: monitor.monitorComponent.object1Name,
        notificationInterval: monitor.notificationInterval,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      getUserType(),
      userPlans?.data?.[0]?.plan?.name || null
    );

    refetchMonitors();
    toggleDeleteModal(false);
  };

  const updateAdditionalContent = (field, value) => {
    dispatch(
      userActions.updatePreferences({
        userId,
        preferenceName: field,
        preferenceIntValue: value,
        primary: true,
      })
    );

    setTimeout(() => {
      refetchMonitors();
    }, 200);
  };

  const handleUpdateMonitor = async (monitor) => {
    await updateMonitor({
      id: monitor.id,
      visible: monitor.visible,
      notificationInterval: monitor.notificationInterval,
      userId,
    });
    refetchMonitors();
  };

  const addSelectedRegions = (item) => {
    const exists = selectedRegions?.filter((t) => t.id === item.id);
    // same tag cannot be added twice, show relevant message
    if (exists.length > 0) return;
    setSelectedRegions([...selectedRegions, { id: item.id, label: item.name }]);
    // create rule
    dispatch(
      tagActions.tagMonitorRuleCreate({
        monitorObjectTagId: selectedMonitor.monitorComponent.object1TagId,
        tagId: selectedMonitor.monitorComponent.object1TagId,
        userId,
        region: item.id,
        categorizeForMonitor: true,
        displayInGraphs: true,
      })
    );

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Region rule edit",
      {
        id: selectedMonitor.id,
        monitorType: selectedMonitor.monitorComponent.object1Type,
        monitorId: selectedMonitor.monitorComponent.object1Id,
        monitorName: selectedMonitor.monitorComponent.object1Name,
        notificationInterval: selectedMonitor.notificationInterval,
        region: item.id,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      getUserType(),
      userPlans?.data?.[0]?.plan?.name || null
    );

    setTimeout(() => {
      refetchMonitors();
    }, 200);
  };

  const removeSelectedRegions = (itemId) => {
    setSelectedRegions(selectedRegions?.filter((i) => i.id !== itemId));
    // delete rule
    dispatch(
      tagActions.tagMonitorRuleDelete({
        monitorObjectTagId: selectedMonitor.monitorComponent.object1TagId,
        tagId: selectedMonitor.monitorComponent.object1TagId,
        userId,
        region: itemId,
      })
    );
    setTimeout(() => {
      refetchMonitors();
    }, 200);
  };

  const addSelectedDiseases = (item) => {
    const exists = selectedDiseases?.filter((t) => t.id === item.id);
    // same disease cannot be added twice, show relevant message
    if (exists.length > 0) return;
    setSelectedDiseases([
      ...selectedDiseases,
      { id: item.tag.id, label: item.label },
    ]);

    // create rule
    dispatch(
      tagActions.tagMonitorRuleCreate({
        monitorObjectTagId: selectedMonitor.monitorComponent.object1TagId,
        tagId: selectedMonitor.monitorComponent.object1TagId,
        userId,
        secondTagId: item.tag.id,
        categorizeForMonitor: true,
        displayInGraphs: true,
      })
    );

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Keyword rule edit",
      {
        id: selectedMonitor.id,
        monitorType: selectedMonitor.monitorComponent.object1Type,
        monitorId: selectedMonitor.monitorComponent.object1Id,
        monitorName: selectedMonitor.monitorComponent.object1Name,
        notificationInterval: selectedMonitor.notificationInterval,
        tagId: item.id,
        tagName: item.name,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      getUserType(),
      userPlans?.data?.[0]?.plan?.name || null
    );

    setTimeout(() => {
      refetchMonitors();
    }, 200);
  };

  const removeSelectedDiseases = (itemId) => {
    setSelectedDiseases(selectedDiseases?.filter((i) => i.id !== itemId));
    // delete rule
    dispatch(
      tagActions.tagMonitorRuleDelete({
        monitorObjectTagId: selectedMonitor.monitorComponent.object1TagId,
        tagId: selectedMonitor.monitorComponent.object1TagId,
        secondTagId: itemId,
        userId,
      })
    );

    setTimeout(() => {
      refetchMonitors();
    }, 200);
  };

  const clearRegionFilters = () => {
    selectedRegions.forEach((region) => {
      removeSelectedRegions(region.id);
    });
    setSelectedRegions([]);
  };

  const clearTagFilters = () => {
    selectedDiseases.forEach((d) => {
      removeSelectedDiseases(d.id);
    });
    setSelectedDiseases([]);
  };

  useEffect(() => {
    if (initialMonitors?.data?.length > 0) {
      let newMonitors = {
        disease: [],
        product: [],
        conference: [],
      };
      initialMonitors.data.forEach((monitor) => {
        if (monitor?.monitorComponent?.object1Type === "DISEASE") {
          newMonitors.disease.push({
            id: monitor.id,
            type: "DISEASE",
            visible: monitor.visible,
            label: monitor.monitorComponent.object1Name,
            notificationInterval: monitor.notificationInterval,
            object1Id: monitor.monitorComponent.object1Id,
            monitorComponent: monitor.monitorComponent,
            monitorRules: monitor.monitorRules,
          });
        }
        if (monitor?.monitorComponent?.object1Type === "PRODUCT") {
          newMonitors.product.push({
            id: monitor.id,
            type: "PRODUCT",
            visible: monitor.visible,
            label: monitor.monitorComponent.object1Name,
            notificationInterval: monitor.notificationInterval,
            object1Id: monitor.monitorComponent.object1Id,
            monitorComponent: monitor.monitorComponent,
            monitorRules: monitor.monitorRules,
          });
        }
        if (monitor?.monitorComponent?.object1Type === "CONFERENCE") {
          newMonitors.conference.push({
            id: monitor.id,
            type: "CONFERENCE",
            visible: monitor.visible,
            label: monitor.monitorComponent.object1Name,
            notificationInterval: monitor.notificationInterval,
            object1Id: monitor.monitorComponent.object1Id,
            monitorComponent: monitor.monitorComponent,
            monitorRules: monitor.monitorRules,
          });
        }
      });
      setMonitors(newMonitors);
    } else {
      setMonitors([]);
    }
  }, [initialMonitors]);

  return (
    <Column stretch>
      <Toast
        visible={showToast}
        toggleVisibility={setShowToast}
        expiration={2000}
      >
        <Row gap="5" middle>
          <Label>{`Payment successful! Thanks for being ${
            queryParams.newPlan === "Enterprise" ? "an" : "a"
          } ${queryParams.newPlan} plan customer.`}</Label>
        </Row>
      </Toast>
      <Row marginBottom marginRight>
        <H3 bold>Manage preferences</H3>
      </Row>

      {bannerData.name && (
        <Banner
          title={`You are following ${bannerData.name}`}
          description={`You will receive ${bannerData.email?.toLowerCase()} email digests about this ${
            bannerData.type
          }.`}
          {...{ setBannerData }}
        />
      )}

      {userPlan || canEditPlan ? (
        <Column
          bkg="gray"
          fade="0.5"
          paddingAll
          radius={adminPage ? null : "10"}
          stretch
        >
          {adminPage ? (
            <Column>
              <Column gap="10" marginTop="10">
                <Label bold large>
                  Plan
                </Label>
                {canEditPlan ? (
                  <Dropdown
                    expand
                    small
                    select
                    width="300"
                    value={userPlan?.plan?.id}
                    items={plansList}
                    onChange={handleUpdatePlan}
                  />
                ) : (
                  <Label>{userPlan?.plan?.name}</Label>
                )}
                {console.log(
                  "selectedPlan?.diseases >> ",
                  selectedPlan?.diseases
                )}
                {adminPage && selectedPlan?.diseases && (
                  <Row marginTop="10">
                    <Label>
                      This plan has
                      <Label bold>
                        {selectedPlan.diseases === "-1"
                          ? " unlimited diseases"
                          : selectedPlan.diseases === "1"
                          ? " 1 disease"
                          : ` ${parseInt(selectedPlan.diseases)} diseases`}
                      </Label>{" "}
                      and
                      <Label bold>
                        {" "}
                        {selectedPlan.products === "-1"
                          ? " unlimited products"
                          : selectedPlan.products === "1"
                          ? " 1 product"
                          : ` ${parseInt(selectedPlan.products)} products`}
                      </Label>
                    </Label>
                  </Row>
                )}
              </Column>
              {!canEditPlan && (
                <Row gap="10" marginTop="10">
                  <Label bold>Period:</Label>
                  <Label>
                    {userPlan?.plan?.period === "MONTH"
                      ? "Monthly"
                      : userPlan?.plan?.period === "DAY"
                      ? "Daily"
                      : "Yearly"}
                  </Label>
                </Row>
              )}
            </Column>
          ) : (
            <Column>
              <Column gap="10">
                <H4 bold>Your plan</H4>
                <Row inline>
                  {userPlan?.plan?.name && (
                    <Label>
                      You are subscribed to{" "}
                      <Label bold>{userPlan?.plan?.name}</Label>, which lets you
                      follow
                      {selectedPlan?.diseases === "-1" ? (
                        <Label>
                          <Dictionary
                            prepend=" unlimited diseases and "
                            word={
                              selectedPlan?.products === "-1"
                                ? "products."
                                : "congresses."
                            }
                          />
                        </Label>
                      ) : (
                        <Label>
                          {selectedPlan?.diseases &&
                            selectedPlan.diseases !== "0" &&
                            ` ${selectedPlan.diseases} ${
                              selectedPlan.diseases === "1"
                                ? "disease"
                                : "diseases"
                            }`}
                          {selectedPlan?.products &&
                            selectedPlan.products !== "0" &&
                            ` and ${selectedPlan.products} ${
                              selectedPlan.products === 1
                                ? "product."
                                : "products."
                            }`}
                        </Label>
                      )}
                    </Label>
                  )}
                </Row>
                {/* only if this is from 'Manage Preferences' page and if the user-plan is not a Stripe or default plan */}
                {!adminPage &&
                  getUserType() !== "HCP" &&
                  getUserType() !== "OTHER" &&
                  userPlan?.customerId?.indexOf("cus_") < 0 &&
                  userPlan.planId !== defaultPlan?.id && (
                    <Row marginY="10">
                      <Anchor
                        bold
                        label="View pricing plans"
                        onClick={() => {
                          logAnalyticEvent(
                            "View pricing plans",
                            {},
                            auth?.id || null,
                            auth?.displayName || null,
                            auth?.email || null,
                            auth?.createdAt || null,
                            getUserType(),
                            userPlan?.plan?.name || null
                          );

                          window.location.href = `/pricing`;
                        }}
                      />
                    </Row>
                  )}
                {(getUserType() === "HCP" || getUserType() === "OTHER") && (
                  <Column marginTop="10">
                    <Label>
                      Need help?{" "}
                      <Anchor
                        label="Contact us"
                        bold
                        blank
                        to="mailto:hello@medical.watch"
                      />
                    </Label>
                  </Column>
                )}

                {/* only if the user-plan is a Stripe plan */}

                {userPlan?.customerId?.indexOf("cus_") > -1 && (
                  <Row marginY="10">
                    <Anchor
                      bold
                      label="Manage billing info"
                      onClick={() => {
                        logAnalyticEvent(
                          "Manage billing info",
                          {},
                          auth?.id || null,
                          auth?.displayName || null,
                          auth?.email || null,
                          auth?.createdAt || null,
                          getUserType(),
                          userPlan?.plan?.name || null
                        );

                        window.location.href = `${getApiPath(
                          false
                        )}/create-portal-session?customer_id=${
                          userPlan.customerId
                        }`;
                      }}
                    />
                  </Row>
                )}
              </Column>
              {userPlan && !canEditPlan && (
                <Row gap="10" marginTop="3">
                  <Label bold>Period:</Label>
                  <Label>
                    {userPlan?.plan?.period === "MONTH"
                      ? "Monthly"
                      : userPlan?.plan?.period === "DAY"
                      ? "Daily"
                      : "Yearly"}
                  </Label>
                </Row>
              )}
            </Column>
          )}
        </Column>
      ) : (
        <Column>
          <Row gap="10" marginTop="3">
            <Label bold>Period:</Label>
            <Label>{defaultPlan?.name}</Label>
          </Row>
        </Column>
      )}
      <Column marginTop="10">
        <Tabs
          active={activeTab}
          tabs={[
            {
              id: "diseases",
              label: <Dictionary prepend="Diseases and " word="products" />,
            },
            { id: "conferences", label: "Congresses" },
            { id: "emails", label: "Email settings" },
            // ...(adminPage ? [] : [{ id: "profile", label: "Profile" }]),
          ]}
          onChange={(e) => setActiveTab(e)}
        />
        <Column border="gray" radiusBottom="20" fade="3" paddingAll>
          {activeTab === "diseases" && (
            <Column gap="20">
              <Column gap="10">
                <Column marginBottom="20" gap="20">
                  <H4 bold>Diseases</H4>
                  <Monitor
                    monitors={monitors?.disease || []}
                    type="DISEASE"
                    {...{
                      intervals,
                      regions,
                      userId,
                      toggleMonitorModal,
                      toggleDeleteModal,
                      setSelectedMonitor,
                      handleMonitorType,
                      selectedPlan,
                      userPlan,
                      toggleFiltersModal,
                      setSelectedRegions,
                      adminPage,
                    }}
                  />
                </Column>
                <Column marginBottom="20" gap="20">
                  <H4 bold>
                    <Dictionary word="Products" />
                  </H4>
                  <Monitor
                    monitors={monitors?.product || []}
                    {...{
                      intervals,
                      regions,
                      userId,
                      toggleMonitorModal,
                      toggleDeleteModal,
                      setSelectedMonitor,
                      handleMonitorType,
                      selectedPlan,
                      userPlan,
                      toggleFiltersModal,
                      setSelectedRegions,
                      setSelectedDiseases,
                      adminPage,
                    }}
                    isHcp={getUserType() === "HCP" || getUserType() === "OTHER"}
                    type="PRODUCT"
                  />
                </Column>
              </Column>
            </Column>
          )}
          {activeTab === "conferences" && (
            <Column gap="20">
              <Label color="gray" fade="8">
                Congresses are automatically generated based on diseases you
                follow
              </Label>
              <Monitor
                monitors={monitors?.conference || []}
                {...{
                  intervals,
                  regions,
                  userId,
                  ...(adminPage && { toggleMonitorModal }),
                  toggleDeleteModal,
                  setSelectedMonitor,
                  handleMonitorType,
                  selectedPlan,
                  conferences,
                  adminPage,
                }}
                type="CONFERENCE"
              />
            </Column>
          )}
          {activeTab === "emails" && (
            <Column gap="20">
              <Label color="gray" fade="8">
                Manage the frequency of your email digests
              </Label>
              <Column marginBottom="20" gap="40">
                <Column gap="10">
                  <H4 bold>Diseases</H4>
                  <MonitorList
                    monitors={monitors?.disease || []}
                    type="DISEASE"
                    {...{
                      intervals,
                      handleUpdateMonitor,
                    }}
                  />
                </Column>
                <Column gap="10">
                  <H4 bold>
                    <Dictionary word="Products" />
                  </H4>
                  <MonitorList
                    monitors={monitors?.product || []}
                    type="PRODUCT"
                    {...{
                      intervals,
                      handleUpdateMonitor,
                    }}
                  />
                </Column>
                <Column gap="10">
                  <H4 bold>Additional content</H4>
                  <AdditionalContent
                    {...{ preferences, updateAdditionalContent }}
                  />
                </Column>
                <Column gap="10">
                  <H4 bold>Congresses</H4>
                  <Label color="gray" fade="8">
                    Emails for conferences are only sent during the dates of the
                    event.
                  </Label>
                  <MonitorList
                    monitors={monitors?.conference || []}
                    type="CONFERENCE"
                    {...{
                      intervals,
                      handleUpdateMonitor,
                    }}
                  />
                </Column>
              </Column>
            </Column>
          )}
          {activeTab === "profile" && (
            <Column gap="20">
              <H4 bold>Location</H4>
              <Column paddingBottom="30">
                <Group label="City">
                  <Input
                    placeholder="City"
                    value={userData.city}
                    onChange={(e) =>
                      handleChange({ value: e.target?.value, field: "city" })
                    }
                    maxCharacters={64}
                    onBlur={handleUpdate}
                  />
                </Group>
                <Group label="State/province">
                  <Input
                    placeholder="State/province"
                    value={userData.stateProvince}
                    onChange={(e) =>
                      handleChange({
                        value: e.target?.value,
                        field: "stateProvince",
                      })
                    }
                    maxCharacters={64}
                    onBlur={handleUpdate}
                  />
                </Group>
                <Group label="Country code">
                  <Input
                    placeholder="Country code"
                    value={userData.country}
                    onChange={(e) =>
                      handleChange({
                        value: e.target?.value,
                        field: "country",
                      })
                    }
                    maxCharacters={2}
                    onBlur={handleUpdate}
                  />
                </Group>
                <Group label="Postal code">
                  <Input
                    type="number"
                    placeholder="Postal code"
                    value={userData.postalCode}
                    onChange={(e) =>
                      handleChange({
                        value: e.target?.value,
                        field: "postalCode",
                      })
                    }
                    maxCharacters={12}
                    onBlur={handleUpdate}
                  />
                </Group>
              </Column>
            </Column>
          )}
        </Column>
      </Column>

      <Modal
        filter
        visible={filtersModal}
        toggleVisibility={toggleFiltersModal}
        onClose={() => {
          setSelectedRegions([]);
          setSelectedDiseases([]);
          toggleFiltersModal(false);
        }}
        title="Filters"
        subtitle={`Control the content included for this ${selectedMonitor.type?.toLowerCase()}.`}
        maxWidth={600}
        autoHeight
        close
        noOverflow
      >
        <Column fit noScroll>
          <Column bkg="white" paddingAll>
            <Column gap="10">
              <Label large bold>
                Regions
              </Label>

              <AutoCompleteWithResponses
                icon="search"
                showOnEmptyTerm
                placeholder="Filter Regions"
                displayTextFromSelected
                searchResults={regions}
                searchTerm={regionTerm}
                setSearchTerm={setRegionTerm}
                // setSearchTerm={() => {}}
                clearSearchTerm
                selectedItems={selectedRegions}
                addItem={addSelectedRegions}
                hidePills
                excludeItems={selectedRegions}
              />

              {selectedRegions.length > 0 ? (
                <Row flexWrap marginTop="10" marginBottom="-10">
                  <Row marginRight marginTop="-10">
                    <Anchor
                      bold
                      label="Clear filters"
                      onClick={clearRegionFilters}
                    />
                  </Row>
                  {selectedRegions.map((region) => (
                    <Pill
                      key={region.id}
                      bkg="green"
                      fade="2"
                      close
                      onClose={() => removeSelectedRegions(region.id)}
                      label={region.label}
                    />
                  ))}
                </Row>
              ) : (
                <Row marginTop="10">
                  <Label>No filters added. Results include all regions.</Label>
                </Row>
              )}
            </Column>
            {selectedMonitor.type === "PRODUCT" && (
              <Column gap="10" marginTop>
                <Label large bold>
                  Indications
                </Label>
                <AutoCompleteWithResponses
                  showOnEmptyTerm
                  searchResults={indications}
                  placeholder="Filter keywords"
                  setSearchTerm={setSearchDisease}
                  searchTerm={searchDisease}
                  selectedItems={selectedDiseases}
                  addItem={addSelectedDiseases}
                  removeItem={removeSelectedDiseases}
                  clearSearchResults={() => {}}
                  searchLoading={false}
                  hidePills
                  excludeItems={selectedDiseases}
                />
                {selectedDiseases.length > 0 ? (
                  <Row flexWrap marginTop="10" marginBottom="-10">
                    <Row marginRight marginTop="-10">
                      <Anchor
                        bold
                        label="Clear filters"
                        onClick={clearTagFilters}
                      />
                    </Row>
                    {selectedDiseases?.map((item) => (
                      <Pill
                        key={item.id}
                        label={item.label}
                        close
                        bkg="green"
                        fade="2"
                        onClose={() => removeSelectedDiseases(item.id)}
                      />
                    ))}
                  </Row>
                ) : (
                  <Row marginTop="10">
                    <Label>
                      No filters added. Results include all keywords.
                    </Label>
                  </Row>
                )}
              </Column>
            )}
          </Column>
        </Column>
      </Modal>

      <Modal
        filter
        visible={monitorModal}
        toggleVisibility={toggleMonitorModal}
        onClose={() => {
          toggleMonitorModal(false);
          handleResetNewMonitor();
        }}
        // title={`Follow ${newMonitor.monitorComponent.object1Type?.toLowerCase()}`}
        title={
          <ModalTitle monitorType={newMonitor.monitorComponent.object1Type} />
        }
        // subtitle={`Receive weekly emails digests of a ${newMonitor.monitorComponent.object1Type?.toLowerCase()}`}
        subtitle={
          <ModalSubtitle
            monitorType={newMonitor.monitorComponent.object1Type}
          />
        }
        maxWidth={600}
        autoHeight
        close
        noOverflow
      >
        <Column fit noScroll>
          <>
            {newMonitor.monitorComponent.object1Type === "DISEASE" && (
              <SelectList
                small
                filter
                onChange={handleSaveMonitor}
                items={diseases}
                excludeItems={monitors.disease?.filter((m) => m.visible)}
              />
            )}
            {newMonitor.monitorComponent.object1Type === "PRODUCT" && (
              <SelectList
                small
                onChange={handleSaveMonitor}
                items={products}
                searchTerm={searchProduct}
                setSearchTerm={handleSearchProduct}
                infiniteScroll={productsInfinteScroll}
                excludeItems={monitors.product
                  ?.filter((m) => m.visible)
                  ?.map((p) => {
                    return { id: p.object1Id, label: p.label };
                  })}
              />
            )}
            {newMonitor.monitorComponent.object1Type === "CONFERENCE" && (
              <SelectList
                small
                filter
                onChange={handleSaveMonitor}
                items={conferences}
                excludeItems={monitors.conference?.filter((m) => m.visible)}
              />
            )}
          </>
        </Column>
      </Modal>

      <Modal
        visible={deleteModal}
        toggleVisibility={toggleDeleteModal}
        maxWidth="600"
      >
        <Column marginTop="-20" center>
          <H3 bold>{`Want to unfollow ${selectedMonitor?.label}?`}</H3>

          <Row marginTop="10">
            <Label center>
              {`This action will remove it from the web and stop new emails`}
            </Label>
          </Row>
          <Row marginTop="40">
            <Grid columns={2}>
              <Button
                secondary
                label="Cancel"
                minWidth={200}
                onClick={() => toggleDeleteModal(false)}
              />
              <Button
                danger
                minWidth={200}
                label={`Confirm`}
                onClick={() => handleDeleteMonitor(selectedMonitor)}
              />
            </Grid>
          </Row>
        </Column>
      </Modal>
    </Column>
  );
};

const ModalTitle = ({ monitorType }) => {
  if (monitorType === "DISEASE") {
    return "Follow disease";
  } else {
    return <Dictionary prepend="Follow " word="product" />;
  }
};

const ModalSubtitle = ({ monitorType }) => {
  if (monitorType === "DISEASE") {
    return "Receive weekly emails digests of a disease";
  } else {
    return (
      <Dictionary
        prepend="Receive weekly emails digests of a "
        word="product"
      />
    );
  }
};
