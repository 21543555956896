import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import moment from "moment";

import useDebounce from "../../../utils/useDebounce";
import {
  userActions,
  settingsActions,
  commonActions,
} from "../../../store/actions";
import { cancelUserRequests } from "../../../store/sagas";
import { getAllUsers } from "../../../store/sagas/userSagas";
import { getEnumCreatedBy } from "../../../store/sagas/commonSagas";
import { view } from "./UsersView";
import {
  getTimeSegmentedSummary,
  getUserTypeSummary,
} from "../../../store/sagas/userSagas";
import { MAX_RESULTS } from "../../../utils/variables";

export const AdminUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // --> mapStateToProp
  const users = useSelector((state) => state.user.all);
  const usersSettings = useSelector((state) => state.settings?.users);
  // <-- mapStateToProp

  // --> STATE
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState(usersSettings?.searchTerm || "");
  const debounceTerm = useDebounce(searchTerm, 700);
  const [sortByOptions] = useState([
    { id: "name", label: "Name" },
    { id: "date", label: "Creation Date" },
  ]);
  const [sortBy, setSortBy] = useState(usersSettings?.sortBy || "date");
  const [sortOrderOptions] = useState([
    { id: "asc", label: "A → Z" },
    { id: "desc", label: "Z → A" },
  ]);
  const [sortOrder, setSortOrder] = useState(
    usersSettings?.sortOrder || "desc"
  );
  const [roleOptions] = useState([
    { id: "ALL", label: "All" },
    { id: "ADMIN", label: "Admin" },
    { id: "HIGHLIGHTSADMIN", label: "Higlights Admin" },
    { id: "USER", label: "User" },
  ]);
  const [role, setRole] = useState(usersSettings?.role || "ALL");
  const [activeOptions] = useState([
    { id: "ALL", label: "All" },
    { id: "true", label: "Active" },
    { id: "false", label: "Inactive" },
  ]);
  const [active, setActive] = useState(usersSettings?.active || "ALL");
  const [createdByOptions, setCreatedByOptions] = useState([
    { id: "ALL", label: "All" },
  ]);
  const [createdBy, setCreatedBy] = useState(usersSettings?.createdBy || "ALL");
  const [userTypeOptions] = useState([
    { id: "ALL", label: "All" },
    { id: "HCP", label: "Physician" },
    { id: "MARKETING", label: "Marketing" },
    { id: "MEDICAL", label: "Medical Affairs" },
    { id: "SALES", label: "Sales" },
    { id: "OTHER", label: "Other" },
  ]);
  const [userType, setUserType] = useState(usersSettings?.userType || "ALL");
  const [country, setCountry] = useState("");
  const debounceCountry = useDebounce(country, 700);

  const [userTypeSummary, setUserTypeSummary] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [timeSegmentedSummary, setTimeSegmentedSummary] = useState([]);
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    dispatch(commonActions.requestUserRoles());

    (async () => {
      // get enum values for 'CreatedBy'
      const _createdByOptions = await getEnumCreatedBy();
      const temp =
        _createdByOptions?.data.map((o) => {
          return {
            id: o.enumStringValue,
            label: o.enumValueDisplayName,
          };
        }) || [];
      setCreatedByOptions([...createdByOptions, ...temp]);
    })();

    return () => {
      dispatch(userActions.clearAll());
      cancelUserRequests.abort();
    };
  }, []);

  useEffect(() => {
    requestUsers(0);
    setPage(0);
  }, [
    dispatch,
    sortBy,
    sortOrder,
    debounceTerm,
    role,
    active,
    createdBy,
    userType,
    debounceCountry,
  ]);

  useEffect(() => {
    setLoading(users.fetching);
  }, [users.fetching]);

  useEffect(() => {
    requestUsersTimeSegmentedSummary();
    requestUserTypeSummary();
  }, [dispatch, debounceTerm, role, active, createdBy, debounceCountry]);
  // <-- EFFECT

  const requestUsers = (pageNum) => {
    dispatch(
      userActions.request({
        pageNum,
        maxResult: MAX_RESULTS,
        ...(searchTerm !== "" && { term: searchTerm }),
        ...(sortBy && { sortName: sortBy }),
        ...(sortOrder && { sortOrder: sortOrder }),
        ...(role !== "ALL" && { role }),
        ...(active !== "ALL" && { active }),
        ...(createdBy !== "ALL" && { createdBy }),
        ...(userType !== "ALL" && { userType }),
        ...(_.trim(country) !== "" && { country }),
      })
    );
  };

  const requestUsersTimeSegmentedSummary = async () => {
    setChartLoading(true);
    setTimeSegmentedSummary([]);
    const temp = await getTimeSegmentedSummary({
      ...(searchTerm !== "" && { term: searchTerm }),
      ...(role !== "ALL" && { role }),
      ...(active !== "ALL" && { active }),
      ...(createdBy !== "ALL" && { createdBy }),
      ...(userType !== "ALL" && { userType }),
      ...(_.trim(country) !== "" && { country }),
      // get last 12 months summary
      startDate: moment()
        .subtract(12, "months")
        .startOf("months")
        .format("YYYY-MM-DDTHH:mm:ss"),
      endDate: moment().endOf("months").format("YYYY-MM-DDTHH:mm:ss"),
    });
    // console.log(temp.data);
    // transform
    if (temp.data.length > 0) {
      setTimeSegmentedSummary(
        temp.data.map((row) => ({
          users: row.count,
          time: moment(row.timeSegment).format("MMM 'YY"),
        }))
      );
    }
    setChartLoading(false);
  };

  const requestUserTypeSummary = async () => {
    setUserTypeSummary([]);
    const temp = await getUserTypeSummary({
      ...(searchTerm !== "" && { term: searchTerm }),
      ...(role !== "ALL" && { role }),
      ...(active !== "ALL" && { active }),
      ...(createdBy !== "ALL" && { createdBy }),
      ...(_.trim(country) !== "" && { country }),
    });
    // console.log(temp.data);
    // transform
    if (temp.data.length > 0) {
      setUserTypeSummary(
        temp.data.map((row) => ({
          category: row.category,
          count: row.count,
          label:
            userTypeOptions.find((o) => o.id === row.category)?.label ||
            row.category,
        }))
      );
    } else {
      setUserTypeSummary([]);
    }
  };

  const handleMoreItems = () => {
    if (!loading) {
      const newPage = page + 1;
      requestUsers(newPage);
      setPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage: !users.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const handleSortByChange = (item) => {
    setSortBy(item.id);
    dispatch(
      settingsActions.set({
        users: { ...usersSettings, sortBy: item.id },
      })
    );
  };

  const handleSortOrderChange = (item) => {
    setSortOrder(item.id);
    dispatch(
      settingsActions.set({
        users: { ...usersSettings, sortOrder: item.id },
      })
    );
  };

  const handleRoleChange = (item) => {
    setRole(item);
    dispatch(
      settingsActions.set({
        users: { ...usersSettings, role: item },
      })
    );
  };

  const handleActiveChange = (item) => {
    setActive(item);
    dispatch(
      settingsActions.set({
        users: { ...usersSettings, active: item },
      })
    );
  };

  const handleCreatedByChange = (item) => {
    setCreatedBy(item);
    dispatch(
      settingsActions.set({
        users: { ...usersSettings, createdBy: item },
      })
    );
  };

  const handleUserTypeChange = (item) => {
    setUserType(item);
    dispatch(
      settingsActions.set({
        users: { ...usersSettings, userType: item },
      })
    );
  };

  const handleSearchTermChange = (e) => {
    if (!_.isEmpty(e)) {
      setSearchTerm(_.trim(e));
    } else {
      setSearchTerm(null);
    }
    dispatch(
      settingsActions.set({
        users: { ...usersSettings, searchTerm: _.trim(e) },
      })
    );
  };

  const handleUserStatusChange = (id, value) => {
    // activate / deactivate user
    dispatch(
      userActions.updateStatus({
        id,
        active: value,
      })
    );
  };

  const exportCSV = async () => {
    const allUsers = await getUsers();
    let csvContent = "";
    // header
    csvContent += `Id, FirstName, LastName, DisplayName, Email, UserType, Role, Active, LastLogin, LastEmail, LastEmailTime, CreatedAt, LastModified\n`;
    _.forEach(allUsers.data?.list, (user) => {
      const lastLogin = user.lastLogin ? new Date(user.lastLogin).toLocaleString('en-US') : '';
      const lastEmail = user.lastNotificationEvent?.category || ""
      const lastEmailTime = user.lastNotificationEvent?.eventTimestamp ? new Date(user.lastNotificationEvent?.eventTimestamp).toLocaleString('en-US') : ""
      csvContent += `"${user.id}",`;
      csvContent += `"${user.firstName}",`;
      csvContent += `"${user.lastName}",`;
      csvContent += `"${user.displayName}",`;
      csvContent += `"${user.email}",`;
      csvContent += `"${user.userType || ''}",`;
      csvContent += `"${user.mwRole}",`;
      csvContent += `"${user.active}",`;
      csvContent += `"${lastLogin}",`;
      csvContent += `"${lastEmail}",`;
      csvContent += `"${lastEmailTime}",`;
      csvContent += `"${new Date(user.createdAt).toLocaleString('en-US')}",`;
      csvContent += `"${new Date(user.lastModified).toLocaleString('en-US')}",`;
      csvContent += `\n`;
    });
    const url = window.URL.createObjectURL(new Blob([csvContent]));
    const link = document.createElement("a");
    link.href = url;
    link.id = "mw-download-link";
    link.setAttribute(
      "download",
      `Users_${moment().format("YYYYMMDDHHmmss")}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  const getUsers = async () => {
    return await getAllUsers({
      ...(searchTerm !== "" && { term: searchTerm }),
      ...(sortBy && { sortName: sortBy }),
      ...(sortOrder && { sortOrder: sortOrder }),
      ...(role !== "ALL" && { role }),
      ...(active !== "ALL" && { active }),
      ...(createdBy !== "ALL" && { createdBy }),
      ...(userType !== "ALL" && { userType }),
      ...(_.trim(country) !== "" && { country }),
    });
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (role !== "ALL") {
      activeFilters.push(
        `Role: ${roleOptions.find((a) => role === a.id)?.label}`
      );
    }
    if (active !== "ALL") {
      activeFilters.push(
        `Status: ${activeOptions.find((a) => active === a.id)?.label}`
      );
    }
    if (country) {
      activeFilters.push(`Country: ${country}`);
    }

    return activeFilters;
  };

  return view({
    history,
    users: users.data || [],
    usersCount: users.count || 0,
    infiniteScrollRef,
    hasNextPage: !users.isLastPage,
    loading,
    activeFilters: getActiveFilters(),

    handleUserStatusChange,

    searchTerm,
    handleSearchTermChange,

    sortByOptions,
    sortBy,
    handleSortByChange,

    sortOrderOptions,
    sortOrder,
    handleSortOrderChange,

    roleOptions,
    role,
    handleRoleChange,

    activeOptions,
    active,
    handleActiveChange,

    createdByOptions,
    createdBy,
    handleCreatedByChange,

    userTypeOptions,
    userType,
    handleUserTypeChange,

    country,
    setCountry,

    userTypeSummary,

    chartLoading,
    timeSegmentedSummary,

    exportCSV,
  });
};
