import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Row, Column, Label, Drawer, List, ListItem, HR, Icon } from ".";
import { sortedMonitors } from "../utils";
import { Link } from "react-router-dom";

export const Menu = ({ selectedMonitor, activeTab, tabOnChange }) => {
  const [monitors, setMonitors] = useState([]);
  const [showAI, setShowAI] = useState(false);
  const unauthMonitors = useSelector((state) => state.monitor.unauth);
  const authMonitors = useSelector((state) => state.monitor.auth?.data);
  const auth = useSelector((state) => state.auth.data);
  const userPlans = useSelector((state) => state.plan.userPlans);

  useEffect(() => {
    setMonitors(sortedMonitors(auth.active ? authMonitors : unauthMonitors));
  }, [authMonitors, unauthMonitors]);

  useEffect(() => {
    if (userPlans?.success) {
      let validPlans = true;
      const planName = userPlans?.data?.[0]?.plan?.name;
      if (planName?.includes("Disease Only")) {
        validPlans = false;
      }
      const enabledAI = auth?.preferences?.find(
        (item) => item.preferenceName === "AIChat"
      )?.preferenceIntValue;
      // const salesRole =
      //   auth?.preferences?.find((item) => item.preferenceName === "UserType")
      //     ?.preferenceStringValue === "SALES";

      if (enabledAI === 0 || enabledAI === 1) {
        setShowAI(enabledAI === 1);
      } else {
        setShowAI(
          validPlans
          // && !salesRole
        );
      }
    }
  }, [auth, userPlans]);

  return (
    <Drawer login user scroll>
      <List marginTop="20">
        {showAI && (
          <Column marginTop="-10">
            <Link
              className={activeTab === "aiChat" ? "active" : ""}
              to="/aiChat"
            >
              <ListItem>
                <Row gap="10">
                  <Icon name="chat" fade="7" color="gray" />
                  AI chat
                </Row>
              </ListItem>
            </Link>
            <Row marginBottom="30" marginTop="10" marginX="-5">
              <HR />
            </Row>
          </Column>
        )}
        {monitors
          ?.filter((m) => m.visible)
          .map((item, index) => {
            let tabs = [];
            switch (item.type) {
              case "DISEASE":
                tabs = [
                  { id: "tweets", label: "Posts" },
                  { id: "influencers", label: "Top Influencers" },
                  // { id: "summary", label: "Highlights" },
                ];
                break;
              case "PRODUCT":
                tabs = [
                  { id: "tweets", label: "Posts" },
                  { id: "influencers", label: "Top Influencers" },
                ];
                break;
              case "CONFERENCE":
                tabs = [
                  { id: "tweets", label: "Posts" },
                  // { id: "summary", label: "Highlights" },
                ];
                break;
            }
            return (
              <Column id={`drawer-${item.id}`} key={item.id}>
                {index !== 0 && (
                  <Row marginBottom="30" marginTop="10" marginX="-5">
                    <HR />
                  </Row>
                )}
                <Row marginBottom="12" marginX="10">
                  <Label bold>{item.label}</Label>
                </Row>
                {tabs
                  ?.filter((a) => a.visible !== false)
                  ?.map((tab) => {
                    return (
                      <Column key={`drawer-section-${tab.id}`}>
                        {selectedMonitor?.id === item.id ? (
                          <div
                            className={
                              selectedMonitor?.id === item.id &&
                              tab.id === activeTab
                                ? "active"
                                : "hover"
                            }
                          >
                            <ListItem onClick={() => tabOnChange(tab.id)}>
                              {tab.label}
                            </ListItem>
                          </div>
                        ) : (
                          <a
                            href={`/?${item.type.toLowerCase()}Id=${
                              item.id
                            }&tab=${tab.id}`}
                          >
                            <ListItem>{tab.label}</ListItem>
                          </a>
                        )}
                      </Column>
                    );
                  })}
              </Column>
            );
          })}
      </List>
    </Drawer>
  );
};
