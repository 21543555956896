import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

export function getStudies({
  objectId,
  overallStatus,
  phase,
  country,
  term,
  nctId,
  startDate,
  pageNum,
  maxResult,
}) {
  const params = objectToUrlEncoded({
    objectId,
    overallStatus,
    phase,
    country,
    term,
    nctId,
    startDate,
    pageNum,
    maxResult,
  });
  return axios.get(`${getApiPath()}/studies?${params}`, {
    headers: authHeader(),
  });
}

export function getStudyLocations({ id, country }) {
  let url = `${getApiPath()}/study/${id}/locations`;
  if (country) {
    url += `?country=${country}`;
  }
  return axios.get(url, { headers: authHeader() });
}

export function syncStudy({ nctId }) {
  return axios.put(
    `${getApiPath()}/study/synchronize?id=${nctId}`,
    {},
    { headers: authHeader() }
  );
}
