import React from "react";
import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Card,
  Label,
  Filters,
  Input,
  Dropdown,
  AutoComplete,
  List,
  ListItem,
  Grid,
  Spinner,
  Anchor,
  Collapsible,
} from "../../../components";

import { Menu } from "../Menu";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

const Group = ({ label, value, link }) => (
  <>
    {value && (
      <Column gap="2" marginBottom="5">
        <Row>
          <Label small color="gray" fade="7">
            {label}
          </Label>
        </Row>
        {link ? (
          <Anchor
            label={value}
            bold
            // color="blue"
            blank
            to={link}
          />
        ) : (
          <Label small bold>
            {value}
          </Label>
        )}
      </Column>
    )}
  </>
);

const HtmlGroup = ({ label, value, truncateLines }) => (
  <>
    {value && (
      <Column gap="2" marginBottom="5">
        <Row>
          <Label small color="gray" fade="7">
            {label}
          </Label>
        </Row>

        {truncateLines > 0 ? (
          <HTMLEllipsis
            unsafeHTML={value}
            maxLine={truncateLines}
            ellipsis="..."
            // onReflow={handleTruncate}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        )}
      </Column>
    )}
  </>
);

export const view = ({
  studies,
  synchronizeStudy,

  loading,
  infiniteScrollRef,
  hasNextPage,
  activeFilters,

  diseases,
  setSearchDisease,
  searchDisease,
  selectedDiseases,
  addSelectedDiseases,
  clearSelectedDiseases,
  diseasesInfiniteScroll,

  overallStatuses,
  overallStatus,
  handleOverallStatusChange,

  phases,
  phase,
  handlePhaseChange,

  country,
  setCountry,

  searchTerm,
  setSearchTerm,

  nctId,
  setNctId,
  startDate,
  setStartDate,

  requestLocations,
  loadingLocations,
}) => {
  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
          </>
        }
      />
      <Menu active="studies" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters {...{ activeFilters }}>
              <Grid columns={4}>
                <Column gap="10">
                  <Label bold>Disease</Label>
                  <AutoComplete
                    showOnEmptyTerm
                    displayTextFromSelected
                    searchResults={diseases}
                    placeholder="Select one"
                    setSearchTerm={setSearchDisease}
                    searchTerm={searchDisease}
                    selectedItems={selectedDiseases}
                    addItem={addSelectedDiseases}
                    clearSearchResults={clearSelectedDiseases}
                    searchLoading={false}
                    hasClearButton
                    infiniteScroll={diseasesInfiniteScroll}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Overall Status</Label>
                  <Dropdown
                    expand
                    select
                    value={overallStatus}
                    items={overallStatuses}
                    onChange={(e) => handleOverallStatusChange(e.id)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Phase</Label>
                  <Dropdown
                    expand
                    select
                    value={phase}
                    items={phases}
                    onChange={(e) => handlePhaseChange(e.id)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Country</Label>
                  <Input
                    small
                    placeholder="e.g., United States"
                    icon="search"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Search</Label>
                  <Input
                    small
                    placeholder="in brief & official titles"
                    // icon="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>NctId</Label>
                  <Input
                    small
                    placeholder="NctId"
                    // icon="search"
                    value={nctId}
                    onChange={(e) => setNctId(e.target.value)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Ingested since</Label>
                  <Input
                    small
                    placeholder="e.g., 2024-10-01"
                    // icon="search"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>

          <Column paddingBottom="40" maxWidth="990">
            {studies.map((study, i) => (
              <Card border key={study.nctId}>
                <Row right gap="10">
                  <Label color="gray">
                    Last synced -{" "}
                    {new Date(study.lastModified).toLocaleString("en-US", {
                      timeZone: "UTC",
                    })}{" "}
                    UTC
                  </Label>
                  <Anchor
                    label="Sync"
                    icon="reload"
                    onClick={() => synchronizeStudy(study.nctId)}
                  />
                </Row>
                <Row middle gap="20" marginTop>
                  <Row gap="10">
                    <Group label="officialTitle" value={study.officialTitle} />
                  </Row>
                </Row>
                <Row middle gap="20" marginTop>
                  <Row gap="10">
                    <Group label="briefTitle" value={study.briefTitle} />
                  </Row>
                </Row>
                <Row middle gap="20" marginTop>
                  <Row gap="10">
                    <HtmlGroup
                      label="briefSummary"
                      value={study.briefSummary || ""}
                      truncateLines="6"
                    />
                  </Row>
                </Row>
                <Row middle gap="20" marginTop>
                  <Row gap="10">
                    <HtmlGroup
                      label="detailedDescription"
                      value={`${study.detailedDescription || ""}`}
                      truncateLines="6"
                    />
                  </Row>
                </Row>

                <Row marginTop>
                  <Label bold>Identification</Label>
                </Row>
                <hr />
                <Grid columns={3} marginTop>
                  <Group
                    label="Id"
                    value={study.nctId}
                    link={`https://clinicaltrials.gov/study/${study.nctId}`}
                  />
                  <Group label="acronym" value={study.acronym || "-"} />
                  <Group
                    label="organizationFullName"
                    value={study.organizationFullName || "-"}
                  />
                  <Group
                    label="organizationClass"
                    value={study.organizationClass || "-"}
                  />
                </Grid>

                <Row marginTop>
                  <Label bold>Status</Label>
                </Row>
                <hr />
                <Grid columns={3} marginTop>
                  {/* <Group
                    label="statusVerifiedDate"
                    value={study.statusVerifiedDate}
                  /> */}
                  <Group label="overallStatus" value={study.overallStatus} />
                  <Group label="startDate" value={study.startDate} />
                  <Group label="completionDate" value={study.completionDate} />
                  <Group
                    label="lastUpdateSubmitDate"
                    value={study.lastUpdateSubmitDate}
                  />
                  <Group
                    label="lastUpdatePostDate"
                    value={study.lastUpdatePostDate}
                  />
                </Grid>

                <Row marginTop>
                  <Label bold>Sponsor & Collaborators</Label>
                </Row>
                <hr />
                <Grid columns={3} marginTop>
                  <Group
                    label="responsiblePartyType"
                    value={study.responsiblePartyType || "-"}
                  />
                  <Group
                    label="investigatorFullName"
                    value={study.investigatorFullName || "-"}
                  />
                  <Group
                    label="investigatorTitle"
                    value={study.investigatorTitle || "-"}
                  />
                  <Group
                    label="investigatorAffiliation"
                    value={study.investigatorAffiliation || "-"}
                  />
                  <Group
                    label="leadSponsorName"
                    value={study.leadSponsorName || "-"}
                  />
                  <Group
                    label="leadSponsorClass"
                    value={study.leadSponsorClass || "-"}
                  />
                </Grid>

                <Row marginTop>
                  <Label bold>Conditions & Keywords</Label>
                </Row>
                <hr />
                <Grid columns={1} marginTop>
                  <Group label="conditions" value={study.conditions || "-"} />
                </Grid>
                <Grid columns={1} marginTop>
                  <Group label="keywords" value={study.keywords || "-"} />
                </Grid>

                <Row marginTop>
                  <Label bold>Phases</Label>
                </Row>
                <hr />
                <Grid columns={3} marginTop>
                  <Group label="phases" value={study.phases || "-"} />
                  <Group label="studyType" value={study.studyType || "-"} />
                </Grid>

                {/* {study.primaryOutcomes && (
                  <>
                    <Row marginTop>
                      <Label bold>Primary Outcomes</Label>
                    </Row>
                    <hr/>
                    {study.primaryOutcomes.map((outcome, i) => (
                      <Grid columns={3} marginTop key={`z-${i}`}>
                        <Group label="measure" value={outcome.measure || "-"} />
                        <Group label="description" value={outcome.description || "-"} />
                        <Group label="timeFrame" value={outcome.timeFrame || "-"} />
                      </Grid>
                    ))}
                    <br/>
                    {study.secondaryOutcomes && study.secondaryOutcomes.length > 1 && (
                      <Label small bold color="blue">Additional {study.secondaryOutcomes.length} secondary outcomes.</Label>
                    )}
                  </>
                )} */}
                {study.contacts && (
                  <>
                    <Row marginTop>
                      <Label bold>Contacts</Label>
                    </Row>
                    <hr />
                    {study.contacts.map((contact, i) => {
                      return (
                        <Grid columns={3} marginTop>
                          <Group label="name" value={contact?.name || "-"} />
                          <Group label="role" value={contact?.role || "-"} />
                          <Group
                            label="affiliation"
                            value={contact?.affiliation || "-"}
                          />
                        </Grid>
                      );
                    })}
                    {study.contacts.length == 0 && (
                      <Row middle fit center marginTop>
                        <Label small bold color="gray" fade="3">
                          No contacts found.
                        </Label>
                      </Row>
                    )}
                    <br />
                  </>
                )}
                <Collapsible
                  label="Locations"
                  onOpen={() =>
                    // !study.locations &&
                    requestLocations(study.nctId)
                  }
                >
                  {loadingLocations ? (
                    <Row expand paddingAll="40" center>
                      <Spinner />
                    </Row>
                  ) : (
                    <>
                      {study.locations && (
                        <Column paddingAll>
                          <Grid columns={4}>
                            <Group
                              label="facility"
                              value={study.locations[0]?.facility || "-"}
                            />
                            <Group
                              label="city"
                              value={study.locations[0]?.city || "-"}
                            />
                            <Group
                              label="stateProvince"
                              value={study.locations[0]?.stateProvince || "-"}
                            />
                            <Group
                              label="country"
                              value={study.locations[0]?.country || "-"}
                            />
                          </Grid>
                          {study.locations.length > 1 && (
                            <Grid columns={4} marginTop>
                              <Group
                                label="facility"
                                value={study.locations[1]?.facility || "-"}
                              />
                              <Group
                                label="city"
                                value={study.locations[1]?.city || "-"}
                              />
                              <Group
                                label="stateProvince"
                                value={study.locations[1]?.stateProvince || "-"}
                              />
                              <Group
                                label="country"
                                value={study.locations[1]?.country || "-"}
                              />
                            </Grid>
                          )}
                          {/* {study.locations.length > 2 && (
                      <Grid columns={4} marginTop>
                        <Group
                          label="facility"
                          value={study.locations[2]?.facility || "-"}
                        />
                        <Group
                          label="city"
                          value={study.locations[2]?.city || "-"}
                        />
                        <Group
                          label="stateProvince"
                          value={study.locations[2]?.stateProvince || "-"}
                        />
                        <Group
                          label="country"
                          value={study.locations[2]?.country || "-"}
                        />
                      </Grid>
                    )} */}
                          <br />
                          {study.locations.length > 3 && (
                            <Label small bold color="gray" fade="3">
                              And more.
                            </Label>
                          )}
                        </Column>
                      )}
                    </>
                  )}
                </Collapsible>
              </Card>
            ))}
          </Column>
          {loading || hasNextPage ? (
            <Row expand paddingAll="40" center ref={infiniteScrollRef}>
              <Spinner />
            </Row>
          ) : (
            <>
              {studies?.length === 0 && (
                <List center>
                  <ListItem>No Studies found</ListItem>
                </List>
              )}
            </>
          )}
        </Column>
      </Content>
    </MainWrapper>
  );
};
