import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import moment from "moment";
import { groupBy } from "lodash";
import useDebounce from "../../../utils/useDebounce";
import { getISOFormattedDateTimeString } from "../../../utils";
import { MAX_RESULTS } from "../../../utils/variables";
import {
  notificationActions,
  userActions,
  settingsActions,
} from "../../../store/actions";
import {
  cancelNotificationRequests,
  cancelUserRequests,
} from "../../../store/sagas";
import { view } from "./NotificationsView";
import { getNotificationSummary } from "../../../store/sagas/notificationSagas";

export const AdminNotifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = moment().endOf("day");
  const lastMonth = moment().add(-2, "weeks").startOf("day");
  const notificationTypes = [
    { id: "ALL", label: "All" },
    { id: "INSTANT", label: "Instant" },
    { id: "DAILY", label: "Daily" },
    { id: "WEEKLY", label: "Weekly" },
    { id: "MONTHLY", label: "Monthly" },
    { id: "USERREQUEST", label: "User Request" },
    { id: "TRIAL", label: "Trial" },
  ];
  const notificationContentTypes = [
    { id: "ALL", label: "All" },
    { id: "DISEASE", label: "Disease" },
    { id: "CONFERENCE", label: "Congress" },
    { id: "PRODUCT", label: "Product" },
    { id: "TOPINFLUENCER", label: "Top Influencers" },
    { id: "TOP_BRAND_MENTIONS", label: "Top Brand Mentions" },
    { id: "TOP_ARTICLES", label: "Top Articles" },
    { id: "CLINICAL_TRIALS", label: "Clinial Trials" },
    { id: "SIGNUP", label: "Sign Up" },
    { id: "SIGNIN", label: "Sign In" },
    { id: "GETTING_STARTED", label: "Getting Started" },
    { id: "SIGNIN_FOR_INACTIVITY", label: "Sign in for inactivity" },
  ];
  const chartTypes = [
    { id: 1, label: "Line" },
    { id: 2, label: "Stacked Columns" },
  ];

  // --> mapStateToProp
  const notifications = useSelector((state) => state.notification.all);
  const users = useSelector((state) => state.user.all);
  const notificationsSettings = useSelector(
    (state) => state.settings?.notifications
  );
  // <-- mapStateToProp

  // --> STATE
  const [dates, setDates] = useState({
    start:
      notificationsSettings?.dates?.start ||
      getISOFormattedDateTimeString(lastMonth),
    end:
      notificationsSettings?.dates?.end ||
      getISOFormattedDateTimeString(currentDate),
  });

  const [selectedUser, setSelectedUser] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const [pageUsers, setPageUsers] = useState("");
  const debounceUser = useDebounce(searchUser, 700);

  const [selectedNotificationType, setSelectedNotificationType] = useState(
    notificationsSettings?.notificationType || "ALL"
  );

  const [selectedNotificationContentType, setSelectedNotificationContentType] =
    useState(notificationsSettings?.notificationContentType || "ALL");

  const [excludeEmployees, setExcludeEmployees] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [timeSegmentedSummary, setTimeSegmentedSummary] = useState([]);
  const [chartType, setChartType] = useState(1);
  const [totalsInChart, setTotalsInChart] = useState(false);
  const [uniqueUsers, setUniqueUsers] = useState(false);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  // <-- STATE

  const requestNotifications = (pageNum) => {
    dispatch(
      notificationActions.request({
        startDate: dates.start,
        endDate: dates.end,
        recipientId: selectedUser?.[0]?.id,
        ...(selectedNotificationType !== "ALL" && {
          notificationType: selectedNotificationType,
        }),
        ...(selectedNotificationContentType !== "ALL" && {
          notificationContentType: selectedNotificationContentType,
        }),
        excludeEmployees,
        pageNum,
        maxResult: MAX_RESULTS,
      })
    );
  };

  const requestUsers = (pageNum) => {
    dispatch(
      userActions.request({
        ...(searchUser !== "" && { term: searchUser }),
        maxResult: MAX_RESULTS,
        pageNum,
      })
    );
  };

  // --> EFFECT
  useEffect(() => {
    // requestNotifications(0, dates.start, dates.end);
    return () => {
      dispatch(notificationActions.clear());
      dispatch(userActions.clear());

      cancelNotificationRequests.abort();
      cancelUserRequests.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    setLoading(notifications.fetching);
  }, [notifications.fetching]);

  useEffect(() => {
    setPage(0);
    requestNotifications(0);
  }, [
    selectedUser,
    dates,
    selectedNotificationType,
    selectedNotificationContentType,
    excludeEmployees,
  ]);

  useEffect(() => {
    (async () => {
      setChartLoading(true);
      setTimeSegmentedSummary([]);
      const dayDiff = moment(dates.end).diff(moment(dates.start), "days");
      // fetch time segmented data
      const temp = await getNotificationSummary({
        recipientId: selectedUser?.[0]?.id,
        ...(selectedNotificationType !== "ALL" && {
          notificationType: selectedNotificationType,
        }),
        ...(selectedNotificationContentType !== "ALL" && {
          notificationContentType: selectedNotificationContentType,
        }),
        excludeEmployees,
        uniqueUsers,
        startDate: dates.start,
        endDate: dates.end,
        segment: dayDiff > 31 ? "WEEK" : "DAY",
      });
      // transform
      if (temp.data.length > 0) {
        const groupByTime = groupBy(temp.data, "timeSegment");
        const data = [];
        Object.keys(groupByTime).forEach((key) => {
          const row = {};
          row.time = key;
          groupByTime[key].forEach((d) => {
            const category = d.category.replaceAll("_", " ");
            if (totalsInChart) row["sent"] = (row["sent"] || 0) + d.count;
            else row[category] = d.count;
          });
          data.push(row);
        });
        setTimeSegmentedSummary(data);
      } else {
        setTimeSegmentedSummary([]);
      }
      setChartLoading(false);
    })();
  }, [
    selectedUser,
    dates,
    selectedNotificationType,
    selectedNotificationContentType,
    excludeEmployees,
    uniqueUsers,
    totalsInChart,
  ]);

  useEffect(() => {
    users.data.forEach((u) => {
      u.label = u.displayName;
    });
  }, [users.data]);

  useEffect(() => {
    setPageUsers(0);
    requestUsers(0);
  }, [debounceUser]);
  // <-- EFFECT

  const handleMoreNotifications = () => {
    if (!loading) {
      const newPage = page + 1;
      requestNotifications(newPage);
      setPage(newPage);
    }
  };

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage: !notifications.isLastPage,
    onLoadMore: handleMoreNotifications,
  });

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    setDates({
      start,
      end,
    });
    dispatch(
      settingsActions.set({
        notifications: {
          ...notificationsSettings,
          dates: {
            start: getISOFormattedDateTimeString(e.startDate),
            end: getISOFormattedDateTimeString(e.endDate),
          },
        },
      })
    );
  };

  const handleNotificationTypeChange = (item) => {
    setSelectedNotificationType(item.id);
    dispatch(
      settingsActions.set({
        notifications: { ...notificationsSettings, notificationType: item.id },
      })
    );
  };

  const handleNotificationContentTypeChange = (item) => {
    setSelectedNotificationContentType(item.id);
    dispatch(
      settingsActions.set({
        notifications: {
          ...notificationsSettings,
          notificationContentType: item.id,
        },
      })
    );
  };

  const handleChartTypeChange = (item) => {
    setChartType(item.id);
  };

  const clearUser = () => {
    setSearchUser("");
    addSelectedUser(null);
  };

  const handleSearchUser = (e) => {
    setSearchUser(e);
  };

  const addSelectedUser = (e) => {
    setSelectedUser([e]);
    dispatch(
      settingsActions.set({
        notifications: { ...notificationsSettings, user: [e] },
      })
    );
  };

  const handleMoreUsers = () => {
    if (!users.fetching) {
      const usersNewPage = pageUsers + 1;
      setPageUsers(usersNewPage);
      requestUsers(usersNewPage);
    }
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (selectedUser?.[0]?.label) {
      activeFilters.push(`User: ${selectedUser[0].label}`);
    }
    if (selectedNotificationType !== "ALL") {
      activeFilters.push(
        `Type: ${
          notificationTypes.find((a) => selectedNotificationType === a.id)
            ?.label
        }`
      );
    }
    if (selectedNotificationContentType !== "ALL") {
      activeFilters.push(
        `Content: ${
          notificationContentTypes.find(
            (a) => selectedNotificationContentType === a.id
          )?.label
        }`
      );
    }
    if (excludeEmployees) {
      activeFilters.push("Exclude Employees");
    }
    return activeFilters;
  };

  return view({
    history,
    infiniteRef,
    hasNextPage: !notifications.isLastPage,
    loading,
    notifications: page === 0 && loading ? [] : notifications.data || [],
    activeFilters: getActiveFilters(),

    chartLoading,
    timeSegmentedSummary,

    users: users.data || [],
    searchUser,
    selectedUser,
    handleSearchUser,
    addSelectedUser,
    clearUser,
    usersInfiniteScroll: {
      loading: users.fetching,
      hasNextPage: !users.isLastPage,
      onLoadMore: handleMoreUsers,
    },

    notificationTypes,
    selectedNotificationType,
    handleNotificationTypeChange,

    notificationContentTypes,
    selectedNotificationContentType,
    handleNotificationContentTypeChange,

    excludeEmployees,
    setExcludeEmployees,

    totalsInChart,
    setTotalsInChart,

    uniqueUsers,
    setUniqueUsers,

    chartTypes,
    chartType,
    handleChartTypeChange,

    dates,
    handleDateChange,
  });
};
