import React from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  MainWrapper,
  Content,
  Column,
  Header,
  Row,
  H3,
  H4,
  Menu,
  Label,
  Icon,
  Input,
  IconButton,
  Metadata,
  Button,
  List,
  ListItem,
  Avatar,
  P,
  BlinkResponse,
  Html,
  HR,
  Anchor,
  Toast,
  Collapsible,
  Spinner,
} from "../../components";
import { Color } from "../../utils/variables";

const VContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  flex-wrap: nowrap;
  width: 100%;
`;

const HContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
  width: 100%;
`;

const Footer = styled.div`
  max-width: 700px;
  width: 100%;
  flex-shrink: 0;
`;

const SubmitWrapper = styled.div`
  position: relative;

  button {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 36px;
    width: 36px;
    background: ${Color("gray", "2")};
    svg {
      fill: ${Color("green", "7")};
    }

    :hover {
      background: ${Color("green", "4")};
    }
  }
`;

const ExampleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  background: ${Color("gray", "0.5")};
  cursor: pointer;

  &:hover {
    background: ${Color("gray", "2")};
  }
`;

const Example = ({ item, handleExampleClick }) => (
  <ExampleWrapper onClick={() => handleExampleClick(item.id)}>
    <Label>"{item.text}"</Label>
    <Row noShrink>
      <Icon name="send" color="green" fade="7" />
    </Row>
  </ExampleWrapper>
);

const PreviousChats = ({ item, handleSessionClick }) => {
  const name = item?.chatHistory
    ?.find((item) => item.includes("User message"))
    ?.replace("User message: ", "");
  if (!name) {
    return null;
  }
  return (
    <Column>
      {name && (
        <ListItem onClick={() => handleSessionClick(item.chatId)} hover>
          <Row gap="10" middle>
            <Row noShrink>
              <Icon name="chat" color="gray" fade="7" />
            </Row>
            <Label lines={2}>{name}</Label>
          </Row>
        </ListItem>
      )}
    </Column>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  background: ${(props) => (props.bkg ? Color("gray", "0.5") : "none")};
`;

const Question = ({
  item,
  username,
  scrollRef,
  isLast,
  // regenerate,
  handleRegenerate,
  handleCopyToClipboard,
  isFetching,
  isSuccess,
  handleSubmitNewChat,
  setQuestion,
}) => {
  return (
    <Column ref={scrollRef}>
      <Row marginTop="20" />
      <Wrapper bkg>
        <Avatar name={username || ""} bkg="purple" fade="5" color="white" />
        <Row marginTop="8" middle gap="10">
          <P>{item.query || item.question}</P>
        </Row>
      </Wrapper>
      <Wrapper>
        <Avatar name="m" lowercase bkg="green" fade="5" color="white" />
        <Row marginTop="8">
          {/* {regenerate && isLast ? (
            <BlinkResponse />
          ) : ( */}
          <>
            {item.response ? (
              <Html string={item.response} replace />
            ) : (
              <>
                {isFetching ? (
                  <BlinkResponse />
                ) : (
                  <>
                    {isSuccess && (
                      <P>
                        Sorry, we could not find an answer for that, please
                        again later.
                      </P>
                    )}
                  </>
                )}
              </>
            )}
          </>
          {/* )} */}
        </Row>
      </Wrapper>

      <Row marginY="10" marginLeft="75">
        {isLast && (
          <Anchor
            bold
            small
            label="Give me more details"
            icon="reload"
            onClick={handleRegenerate}
          />
        )}
        {item.response && (
          <Row fit right marginRight="10">
            <CopyToClipboard
              text={item.response}
              onCopy={() => handleCopyToClipboard()}
            >
              <Anchor small bold label="Copy to clipboard" icon="copy" />
            </CopyToClipboard>
          </Row>
        )}
      </Row>
    </Column>
  );
};

const RightMenu = ({
  body,
  previousChats,
  chatsLoading,
  handleSessionClick,
  handleNewChatClick,
  showAll,
  setShowAll,
  // isAdmin,
  // params,
  // setParams,
}) => {
  // const yesterday = moment().subtract("5", "days").format("DD/MM/YYYY");
  const previousChatsFiltered = showAll
    ? previousChats
    : previousChats
        // ?.filter((a) => moment(a.lastModified).format("DD/MM/YYYY") > yesterday)
        ?.slice(0, 5);
  const displayShowAll = previousChats?.length > 5;
  return (
    <Column
      show={!body ? "laptopL" : ""}
      hide={body ? "laptopL" : ""}
      stretch
      maxWidth={body ? "700" : "300"}
      paddingTop
    >
      <Column gap="20" marginBottom>
        {body && (
          <Column marginTop marginBottom>
            <HR />
          </Column>
        )}
        <Row>
          <Button
            small
            onClick={() => handleNewChatClick()}
            label="New chat"
            icon="add"
            minWidth={180}
          />
        </Row>
        {/* {isAdmin && (
          <Column left marginY="-10">
            <Collapsible noBorder label="Settings">
              <Column paddingAll gap="20">
                <Column gap="5">
                  <Label>Chat size</Label>
                  <Input
                    small
                    type="number"
                    min="1"
                    max="20"
                    step="1"
                    placeholder="Chat size"
                    value={params.chatsize}
                    onChange={(e) =>
                      setParams((oldParams) => ({
                        ...oldParams,
                        chatsize: e.target.value,
                      }))
                    }
                  />
                </Column>
                <Column gap="5">
                  <Label>Max results</Label>
                  <Input
                    small
                    type="number"
                    min="1"
                    max="20"
                    step="1"
                    placeholder="Max results"
                    value={params.maxresults}
                    onChange={(e) =>
                      setParams((oldParams) => ({
                        ...oldParams,
                        maxresults: e.target.value,
                      }))
                    }
                  />
                </Column>
                <Column gap="5">
                  <Label>Score</Label>
                  <Input
                    small
                    type="number"
                    min="0"
                    max="1"
                    step="0.01"
                    placeholder="Score"
                    value={params.score}
                    onChange={(e) =>
                      setParams((oldParams) => ({
                        ...oldParams,
                        score: e.target.value,
                      }))
                    }
                  />
                </Column>
              </Column>
            </Collapsible>
          </Column>
        )} */}
        {chatsLoading ? (
          <Column center stretch marginTop>
            <Spinner />
          </Column>
        ) : (
          previousChatsFiltered?.length > 0 && (
            <Column left>
              <H4 bold>Previous chats</H4>
              {displayShowAll && (
                <Row marginTop>
                  <Anchor
                    bold
                    label={showAll ? "Show recent" : "Show all"}
                    href="/aiChat"
                    onClick={() => setShowAll(!showAll)}
                  />
                </Row>
              )}
              <List marginTop="15" marginLeft="-10">
                {previousChatsFiltered?.map((item, index) => (
                  <PreviousChats
                    key={`prevChat-${index}`}
                    {...{ item, handleSessionClick }}
                  />
                ))}
              </List>
            </Column>
          )
        )}
      </Column>
    </Column>
  );
};

export const view = ({
  isAdmin,
  examples,
  isExample,
  handleExampleClick,
  question,
  setQuestion,
  handleSubmitNewChat,
  previousChats,
  chatsLoading,
  handleSessionClick,
  waitForQuestion,
  username,
  data,
  previousData,
  scrollRef,
  showAll,
  setShowAll,
  // regenerate,
  handleRegenerate,
  showToast,
  setShowToast,

  handleCopyToClipboard,
  handleNewChatClick,

  isFetching,
  isSuccess,
  params,
  setParams,
  bodyRef,
  inputRef,
}) => (
  <MainWrapper drawer className="dashboard">
    <Metadata />
    <Header
      drawer
      login
      user
      // signUpClick={handleSignUpClick}
    />
    <Menu activeTab="aiChat" />
    <Content noScroll>
      <HContainer>
        <VContainer>
          <Body ref={bodyRef}>
            <Column stretch>
              {!data?.[0] && !previousData?.[0] && !waitForQuestion && (
                <Column marginTop gap="20">
                  <H3 bold>Examples</H3>
                  <Column stretch gap="10">
                    {examples?.map((item) => (
                      <Example
                        key={`example-${item.id}`}
                        {...{ item, handleExampleClick }}
                      />
                    ))}
                  </Column>
                </Column>
              )}

              {/* initial question /aiChat */}
              {previousData.length === 0 && data?.length > 0 && (
                <>
                  {data?.map((item, index) => (
                    <Question
                      key={`question-2-${item.id}`}
                      isLast={
                        !waitForQuestion ? index === data.length - 1 : false
                      }
                      {...{
                        username,
                        item,
                        isFetching,
                        isSuccess,
                        scrollRef,
                        // regenerate,
                        handleRegenerate,
                        setShowToast,
                        handleCopyToClipboard,
                      }}
                    />
                  ))}
                </>
              )}

              {/* after first question /aiChat/[i] */}
              {previousData?.length > 0 && (
                <>
                  {previousData?.map((item, index) => (
                    <Question
                      key={`question-1-${item.id}`}
                      isLast={
                        !waitForQuestion
                          ? index === previousData.length - 1
                          : false
                      }
                      {...{
                        username,
                        item,
                        isFetching,
                        isSuccess,

                        scrollRef,
                        // regenerate,
                        handleRegenerate,
                        setShowToast,
                        handleCopyToClipboard,
                      }}
                    />
                  ))}
                </>
              )}

              {waitForQuestion && (
                <Question
                  item={{ question: waitForQuestion }}
                  {...{
                    username,
                    scrollRef,
                    isFetching,
                    isSuccess,
                    handleSubmitNewChat,
                    setQuestion,
                    handleRegenerate,
                  }}
                />
              )}
            </Column>
            <RightMenu
              body
              {...{
                isAdmin,
                previousChats,
                chatsLoading,
                handleSessionClick,
                handleNewChatClick,
                showAll,
                setShowAll,
                params,
                setParams,
              }}
            />
          </Body>
          <Footer>
            <Column gap="10" marginBottom="-10" stretch>
              <form onSubmit={handleSubmitNewChat}>
                <SubmitWrapper>
                  <Input
                    inputRef={inputRef}
                    autoFocus
                    small
                    disabled={waitForQuestion}
                    placeholder="Looking for medical trends? Let's chat!"
                    expand
                    icon="send"
                    reverse
                    value={isExample ? "" : question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                  <IconButton
                    icon="send"
                    type="submit"
                    id="button-send"
                    onClick={handleSubmitNewChat}
                  />
                </SubmitWrapper>
              </form>
            </Column>
            <Column stretch maxWidth="300" />
          </Footer>
        </VContainer>
        <RightMenu
          {...{
            chatsLoading,
            previousChats,
            handleSessionClick,
            handleNewChatClick,
            showAll,
            setShowAll,
            isAdmin,
            params,
            setParams,
          }}
        />
      </HContainer>
    </Content>
    <Toast
      visible={showToast}
      toggleVisibility={setShowToast}
      expiration={2000}
    >
      <Row gap="5" middle>
        <Icon name="check" />
        <Label>Text copied!</Label>
      </Row>
    </Toast>
  </MainWrapper>
);
