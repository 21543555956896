import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";

export function updateTags(tags) {
  let tagIdList = "";
  if (tags?.length > 0) {
    tags.forEach((tag) => {
      tagIdList += `id=${tag}&`;
    });
  }
  if (tagIdList) {
    return axios.put(
      `${getApiPath()}/tags/exactMatch?${tagIdList}`,
      {},
      { headers: authHeader() }
    );
  } else {
    return Promise.reject("No tags to update");
  }
}
export function useUpdateTags() {
  return useMutation((tags) => updateTags(tags), {});
}
