import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { authHeader } from "../store";
import { USER_ACTIONS, userActions } from "../actions";
import { objectToUrlEncoded } from "../../utils/constants";

export let cancelUserRequests = new AbortController();

export function getAllUsers({
  term,
  sortName,
  sortOrder,
  role,
  active,
  createdBy,
  userType,
  country,
}) {
  const params = objectToUrlEncoded({
    term,
    pageNum: 0,
    maxResult: -1,
    sortName: sortName || "name",
    sortOrder: sortOrder || "asc",
    role,
    active,
    createdBy,
    userType,
    country,
  });
  return axios.get(`${getApiPath()}/users?${params}`, {
    headers: authHeader(),
  });
}

export const getTimeSegmentedSummary = ({
  term,
  role,
  active,
  createdBy,
  userType,
  country,
  startDate,
  endDate,
  segment,
}) => {
  const params = objectToUrlEncoded({
    term,
    role,
    active,
    createdBy,
    userType,
    country,
    startDate,
    endDate,
    segment,
  });

  return axios.get(`${getApiPath()}/users/timeSegmentedSummary?${params}`, {
    headers: authHeader(),
  });
};

export const getUserTypeSummary = ({ term, role, active, createdBy, country }) => {
  const params = objectToUrlEncoded({
    term,
    role,
    active,
    createdBy,
    country,
  });

  return axios.get(`${getApiPath()}/users/userTypeSummary?${params}`, {
    headers: authHeader(),
  });
};

function* handleRequest(action) {
  const {
    term,
    monitorObjectId,
    pageNum,
    maxResult,
    sortName,
    sortOrder,
    role,
    active,
    createdBy,
    userType,
    country,
  } = action.payload;
  const params = objectToUrlEncoded({
    term,
    monitorObjectId,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    sortName,
    sortOrder,
    role,
    active,
    createdBy,
    userType,
    country,
  });
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(axios.get, `${getApiPath()}/users?${params}`, {
      headers: authHeader(),
      signal: cancelUserRequests.signal,
    });
    // NOTE - Without 'monitorObjectId' the payload returned includes 'list' as well as 'count'.
    yield put(
      userActions.success({
        data: data.list || data || [],
        ...(data.count && { count: data.count }), // do not include count, if not present, i.e., allow overriding of count
        pageNum,
      })
    );
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(userActions.failure({ error: { ...e } }));
  }
}

function* handleRequestOne(action) {
  try {
    cancelUserRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/user/${id}?${Date.now()}`,
      {
        headers: authHeader(),
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.oneSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(userActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * create a user
 */
function* handleCreate(action) {
  const body = action.payload.data;
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/user`, body, {
      headers: authHeader(),
      signal: cancelUserRequests.signal,
    });
    yield put(userActions.oneSuccess({ data }));
    // yield put((window.location.href = "/admin/users"));
  } catch (e) {
    yield put(userActions.oneFailure({ error: { ...e } }));
  }
}

function* handleSignup(action) {
  const body = action.payload.data;
  const { customerId } = body;
  if (customerId) {
    delete body.customerId;
  }
  try {
    cancelUserRequests = new AbortController();

    const { data } = yield call(
      axios.post,
      `${getApiPath(false)}/signup${
        customerId ? `?customerId=${customerId}` : ""
      }`,
      body,
      {
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.sendEmailSuccess({ data }));
  } catch (e) {
    yield put(userActions.sendEmailFailure({ error: { ...e } }));
  }
}

function* handleResendSignup(action) {
  const body = action.payload.data;

  try {
    cancelUserRequests = new AbortController();

    yield call(axios.post, `${getApiPath(false)}/resendSignup`, body, {
      signal: cancelUserRequests.signal,
    });
  } catch (e) {
    yield put(userActions.sendEmailFailure({ error: { ...e } }));
  }
}

/**
 * update a user
 */
function* handleUpdate(action) {
  const body = action.payload.data;
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/user`, body, {
      headers: authHeader(),
      signal: cancelUserRequests.signal,
    });
    yield put(userActions.oneSuccess({ data }));
  } catch (e) {
    yield put(userActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * update a user
 */
function* handleUpdateStatus(action) {
  const body = action.payload.data;
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(
      axios.put,
      `${getApiPath()}/user/status`,
      body,
      {
        headers: authHeader(),
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.oneSuccess({ data }));
  } catch (e) {
    yield put(userActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * delete a user
 */
function* handleDelete(action) {
  const { id } = action.payload;
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(axios.delete, `${getApiPath()}/user?id=${id}`, {
      headers: authHeader(),
      signal: cancelUserRequests.signal,
    });
    yield put(userActions.oneSuccess({ data }));
    yield put((window.location.href = "/admin/users"));
  } catch (e) {
    yield put(userActions.oneFailure({ error: { ...e } }));
  }
}

// get count of users
function* handleCountRequest(action) {
  const { term, role, active, createdBy } = action.payload;
  const params = objectToUrlEncoded({ term, role, active, createdBy });
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/users/count?${params}`,
      {
        headers: authHeader(),
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.countSuccess({ data }));
  } catch (e) {
    yield put(userActions.countFailure({ error: { ...e } }));
  }
}

// login email
function* handleSendLoginEmail(action) {
  const body = action.payload.data;
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath(false)}/signIn`,
      body,
      {
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.sendEmailSuccess({ data }));
  } catch (e) {
    console.log("e :>> ", e);
    yield put(userActions.sendEmailFailure({ error: e?.response }));
  }
}

function* handleRequestPreferences(action) {
  const { id, name } = action.payload.data;
  const params = objectToUrlEncoded({
    name,
  });
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      name
        ? `${getApiPath()}/user/${id}/preference?${params}`
        : `${getApiPath()}/user/${id}/preferences`,
      {
        headers: authHeader(),
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.successPreferences({ data }));
  } catch (e) {
    yield put(userActions.failurePreferences({ error: { ...e } }));
  }
}

function* handleUpdatePreferences(action) {
  const body = action.payload.data;
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(
      axios.put,
      `${getApiPath()}/user/${body.userId}/preference`,
      body,
      {
        headers: authHeader(),
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.successPreferences({ data }));
  } catch (e) {
    yield put(userActions.failurePreferences({ error: { ...e } }));
  }
}

function* handleDeletePreferences(action) {
  const { name, userId } = action.payload.data;
  try {
    cancelUserRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/user/${userId}/preference?name=${name}`,
      {
        headers: authHeader(),
        signal: cancelUserRequests.signal,
      }
    );
    yield put(userActions.successPreferences({ data: data[0] }));
  } catch (e) {
    yield put(userActions.failurePreferences({ error: { ...e } }));
  }
}

function* watchUserSagas() {
  yield all([
    takeLatest(USER_ACTIONS.REQUEST, handleRequest),
    takeLatest(USER_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(USER_ACTIONS.UPDATE, handleUpdate),
    takeLatest(USER_ACTIONS.UPDATE_STATUS, handleUpdateStatus),
    takeLatest(USER_ACTIONS.CREATE, handleCreate),
    takeLatest(USER_ACTIONS.SIGNUP, handleSignup),
    takeLatest(USER_ACTIONS.RESEND_SIGNUP, handleResendSignup),
    takeLatest(USER_ACTIONS.DELETE, handleDelete),
    takeLatest(USER_ACTIONS.COUNT_REQUEST, handleCountRequest),
    takeLatest(USER_ACTIONS.SEND_LOGIN_EMAIL, handleSendLoginEmail),
    takeLatest(USER_ACTIONS.REQUEST_PREFERENCES, handleRequestPreferences),
    takeLatest(USER_ACTIONS.UPDATE_PREFERENCES, handleUpdatePreferences),
    takeLatest(USER_ACTIONS.DELETE_PREFERENCES, handleDeletePreferences),
  ]);
}

export default watchUserSagas;
