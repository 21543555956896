import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useDebounce from "../../../utils/useDebounce";
import { diseaseActions } from "../../../store/actions";
import { view } from "./StudiesView";
import moment from "moment";
import {
  getStudies,
  getStudyLocations,
  syncStudy,
} from "../../../store/sagas/studySagas";

export const AdminStudies = () => {
  const dispatch = useDispatch();
  const maxStudies = 5;
  const overallStatuses = [
    { id: "ALL", label: "All" },
    { id: "ACTIVE_NOT_RECRUITING", label: "Active, not recruiting" },
    { id: "COMPLETED", label: "Completed" },
    { id: "ENROLLING_BY_INVITATION", label: "Enrolling by invitation" },
    // { id: "NOT_YET_RECRUITING", label: "Not yet recruiting" },
    { id: "RECRUITING", label: "Recruiting" },
    // { id: "SUSPENDED", label: "Suspended" },
    // { id: "TERMINATED", label: "Terminated" },
    // { id: "WITHDRAWN", label: "Withdrawn" },
    // { id: "AVAILABLE", label: "Available" },
    // { id: "NO_LONGER_AVAILABLE", label: "No longer available" },
    // { id: "TEMPORARILY_NOT_AVAILABLE", label: "TEMPORARILY_NOT_AVAILABLE" },
    { id: "APPROVED_FOR_MARKETING", label: "Approved for marketing" },
    // { id: "WITHHELD", label: "Withheld" },
    // { id: "UNKNOWN", label: "Unknown" },
  ];

  const phases = [
    { id: "ALL", label: "All" },
    { id: "2", label: "Phase 2" },
    { id: "3", label: "Phase 3" },
    { id: "4", label: "Phase 4" },
    // { id: "2 3", label: "Phase 2 or 3" },
    // { id: "3 4", label: "Phase 3 or 4" },
    // { id: "2 3 4", label: "Phase 2 or 3 or 4" },
  ];

  // --> mapStateToProp
  // const users = useSelector((state) => state.user.all);
  const initialDiseases = useSelector((state) => state.disease.all);
  // <-- mapStateToProp

  // --> STATE
  const [loading, setLoading] = useState(false);

  const [diseases, setDiseases] = useState([]);
  const [pageDiseases, setPageDiseases] = useState(0);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [searchDisease, setSearchDisease] = useState("");
  const debounceSearchDisease = useDebounce(searchDisease, 700);

  const [overallStatus, setOverallStatus] = useState("RECRUITING");
  const [phase, setPhase] = useState("ALL");
  const [country, setCountry] = useState("");
  const debounceCountry = useDebounce(country, 700);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);
  const [nctId, setNctId] = useState("");
  const debounceNctId = useDebounce(nctId, 700);
  const [startDate, setStartDate] = useState(
    moment().subtract(3, "weeks").format("YYYY-MM-DD")
  );
  const debounceStartDate = useDebounce(startDate, 1000);

  const [studies, setStudies] = useState([]);
  const [studiesPage, setStudiesPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loadingLocations, setLoadingLocations] = useState(false);

  // <-- STATE

  const requestStudies = async (pageNum) => {
    setLoading(true);
    // if page 0, flush result
    if (pageNum === 0) setStudies([]);
    const allStudies = await getStudies({
      ...(selectedDiseases.length > 0 && { objectId: selectedDiseases[0].id }),
      ...(overallStatus !== "ALL" && { overallStatus }),
      ...(phase !== "ALL" && { phase }),
      ...(_.trim(country) !== "" && { country }),
      ...(_.trim(searchTerm) !== "" && { term: searchTerm }),
      ...(_.trim(nctId) !== "" && { nctId }),
      ...(_.trim(startDate) !== "" && { startDate }),
      pageNum,
      maxResult: maxStudies,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        return [];
      });

    setHasNextPage(allStudies.length >= maxStudies);
    if (pageNum === 0) {
      setStudies(allStudies);
    } else {
      setStudies([...studies, ...allStudies]);
    }
    setLoading(false);
  };

  const requestLocations = async (id) => {
    setLoadingLocations(true);
    const currentStudy = studies.find((study) => study.nctId === id);
    const locations = await getStudyLocations({
      id,
      ...(country !== "" && { country }),
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        return [];
      });

    currentStudy.locations = locations;
    setStudies([...studies, currentStudy]);

    setLoadingLocations(false);
  };

  const synchronizeStudy = async (nctId) => {
    const study = await syncStudy({ nctId })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        return {};
      });
    return study;
  };

  const requestDiseases = (pageNum) => {
    dispatch(
      diseaseActions.request({
        ignore: false,
        ...(searchDisease !== "" && { term: searchDisease }),
        maxResult: 20,
        pageNum,
      })
    );
  };

  const handleMoreDiseases = () => {
    if (!initialDiseases.fetching) {
      const diseasesNewPage = pageDiseases + 1;
      setPageDiseases(diseasesNewPage);
      requestDiseases(diseasesNewPage);
    }
  };

  // --> EFFECT
  useEffect(() => {
    // requestStudies();
  }, [dispatch]);

  useEffect(() => {
    setPageDiseases(0);
    requestDiseases(0);
  }, [debounceSearchDisease]);

  useEffect(() => {
    if (initialDiseases.data.length > 0) {
      let newDiseases = initialDiseases.data.map((item) => ({
        id: item.id,
        label: item.primaryName,
      }));
      setDiseases(newDiseases);
    }
  }, [initialDiseases.data]);

  useEffect(() => {
    requestStudies(0);
    setStudiesPage(0);
  }, [
    selectedDiseases,
    overallStatus,
    phase,
    debounceCountry,
    debounceTerm,
    debounceNctId,
    debounceStartDate,
  ]);
  // <-- EFFECT

  const handleOverallStatusChange = (item) => {
    setOverallStatus(item);
  };

  const handlePhaseChange = (item) => {
    setPhase(item);
  };

  const addSelectedDiseases = (item) => {
    setSelectedDiseases([
      {
        id: item.id,
        label: item.label,
      },
    ]);
  };

  const clearSelectedDiseases = () => {
    setSelectedDiseases([]);
    setSearchDisease("");
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (selectedDiseases.length > 0) {
      activeFilters.push(`Disease: ${selectedDiseases[0].label}`);
    }
    if (overallStatus && overallStatus !== "ALL") {
      activeFilters.push(`Overall Status: ${overallStatus}`);
    }
    if (phase && phase !== "ALL") {
      activeFilters.push(`Phase: ${phase}`);
    }
    if (_.trim(country) !== "") {
      activeFilters.push(`Country: ${country}`);
    }
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (nctId) {
      activeFilters.push(`NctId: ${nctId}`);
    }
    if (startDate) {
      activeFilters.push(`StartDate: ${startDate}`);
    }

    return activeFilters;
  };

  const handleMoreStudies = () => {
    if (!loading) {
      const newPage = studiesPage + 1;
      requestStudies(newPage);
      setStudiesPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleMoreStudies,
  });

  return view({
    studies,
    synchronizeStudy,

    loading,
    hasNextPage,
    infiniteScrollRef,
    activeFilters: getActiveFilters(),

    diseases,
    setSearchDisease,
    searchDisease,
    selectedDiseases,
    addSelectedDiseases,
    clearSelectedDiseases,
    diseasesInfiniteScroll: {
      loading: initialDiseases.fetching,
      hasNextPage: !initialDiseases.isLastPage,
      onLoadMore: handleMoreDiseases,
    },

    overallStatuses,
    overallStatus,
    handleOverallStatusChange,

    phases,
    phase,
    handlePhaseChange,

    country,
    setCountry,

    searchTerm,
    setSearchTerm,

    nctId,
    setNctId,

    startDate,
    setStartDate,

    requestLocations,
    loadingLocations,
  });
};
