import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { Color } from "../utils/variables";

export const H1 = styled.h1`
  text-align: ${(props) =>
    props.center
      ? "center"
      : props.right
      ? "right"
      : props.left
      ? "left"
      : "inherit"};
  font-size: 60px;
  line-height: 65px;
  ${(props) =>
    props.like === "h2" &&
    `font-size: 36px;
    line-height: 40px;`};
  margin: 0;
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  ${(props) =>
    props.truncate &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `};
  font-family: "Oxigen-Bold";
  font-weight: 400;
`;

export const H2 = styled.h2`
  text-align: ${(props) =>
    props.center
      ? "center"
      : props.right
      ? "right"
      : props.left
      ? "left"
      : "inherit"};
  font-size: 36px;
  line-height: 40px;
  ${(props) =>
    props.like === "h4" &&
    `font-size: 20px;
    line-height: 24px;`};
  margin: 0;
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  ${(props) => !props.regular && `font-family: "Oxigen-Bold"`};
  font-weight: 400;

  title {
    display: block;
  }
`;

export const H3 = styled.h3`
  text-align: ${(props) =>
    props.center
      ? "center"
      : props.right
      ? "right"
      : props.left
      ? "left"
      : "inherit"};
  font-size: 24px;
  line-height: 28px;
  ${(props) =>
    props.like === "h2" &&
    `font-size: 36px;
    line-height: 40px;`};
  margin: 0;
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  ${(props) => props.bold && `font-family: "Oxigen-Bold"`};
  font-weight: 400;
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  strong {
    ${(props) =>
      props.highlightColor && `color: ${Color(props.highlightColor)}`};
  }
`;

export const H4 = styled.h4`
  text-align: ${(props) =>
    props.center
      ? "center"
      : props.right
      ? "right"
      : props.left
      ? "left"
      : "inherit"};
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  ${(props) => props.bold && `font-family: "Oxigen-Bold"`};
  ${(props) => props.noShrink && `flex-shrink: 0`};
  font-weight: 400;
`;

export const H5 = styled.h5`
  text-align: ${(props) =>
    props.center
      ? "center"
      : props.right
      ? "right"
      : props.left
      ? "left"
      : "inherit"};
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  ${(props) => props.bold && `font-family: "Oxigen-Bold"`};
  ${(props) => props.noShrink && `flex-shrink: 0`};
  font-weight: 400;
`;

export const Label = styled.span`
  text-align: ${(props) =>
    props.center
      ? "center"
      : props.right
      ? "right"
      : props.left
      ? "left"
      : "inherit"};
  ${(props) => props.noShrink && `flex-shrink: 0`};
  font-size: ${(props) =>
    props.large
      ? "18px"
      : props.small
      ? "14px"
      : props.xsmall
      ? "12px"
      : "16px"};
  line-height: ${(props) =>
    props.text
      ? "1.6"
      : props.large
      ? "22px"
      : props.small
      ? "18px"
      : props.xsmall
      ? "14px"
      : "20px"};
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  ${(props) =>
    props.truncate &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `};
  ${(props) =>
    props.lines &&
    `
      display: -webkit-box;
      -webkit-line-clamp: ${props.lines};
      -webkit-box-orient: vertical;  
      overflow: hidden;
    `};
  ${(props) => props.noWrap && `white-space: nowrap;`};
  &:hover {
    ${(props) =>
      props.clickable &&
      `
      cursor: pointer;
      color: ${Color("green", "7")};    
    `}
  }
  ${(props) => props.bold && `font-family: "Oxigen-Bold"`};
`;

export const UL = styled.ul`
  margin: 0 0 20px 0;
  list-style: disc;
`;

export const LI = styled.li`
  margin: 0 0 5px 40px;
`;

export const P = styled.div`
text-align: ${(props) =>
  props.center
    ? "center"
    : props.right
    ? "right"
    : props.left
    ? "left"
    : "inherit"};
  font-size: ${(props) =>
    props.large ? "20px" : props.small ? "14px" : "16px"};
  line-height: 1.5;
  margin: 0 0 10px;
  ${(props) => props.color && `color: ${Color(props.color, props.fade)}`};
  ${(props) => props.bold && `font-family: "Oxigen-Bold"`};

  a span {
    font-size: ${(props) =>
      props.large ? "20px" : props.small ? "13px" : "15px"} !important;
  }

  a.tweet-url {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-bottom: -6px;
    max-width: 190px;
    
    ${(props) =>
      props.large &&
      css`
        margin-bottom: -9px;
        max-width: 280px;
      `}

  & p {
    margin-bottom: 20px;
  }

  & p:last-child {
    margin-bottom: 0;
  }

 
`;

export const HoverLink = styled(Link)`
  &:hover {
    H1,
    H2,
    H3,
    H4 {
      transition: all 0.2s ease;
      color: ${Color("blue")};
    }
  }
`;
